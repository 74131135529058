<template>
  <div class="main-container docs-page">
    <router-view></router-view>

    <header class="header fixed-top">
      <div class="branding docs-branding">
        <div class="container-fluid position-relative py-2">
          <div class="docs-logo-wrapper">
            <button @click="showNav = !showNav" class="docs-sidebar-toggler docs-sidebar-visible mr-2 d-xl-none">
              <span></span>
              <span></span>
              <span></span>
            </button>
            <div class="site-logo">
              <a class="navbar-brand" href="/">
                <img class="logo-icon mr-2" src="../assets/img/logo/logo-dark.png" alt="logo" />
              </a>
            </div>
          </div>
          <!--//docs-logo-wrapper-->
          <div class="docs-top-utilities d-flex justify-content-end align-items-center">
            <div class="header-config-wrapper btn-group">
              <a href="https://app.modeck.io/login">
                <span style="padding: 16px 50px; display: inline-block; font-weight: 500">Log in</span>
              </a>
              <a href="https://app.modeck.io/login?signup=1" class="ht-btn ht-btn--outline hire-btn d-none d-xl-block">Sign up for free</a>
            </div>
          </div>
          <!--//docs-top-utilities-->
        </div>
        <!--//container-->
      </div>
      <!--//branding-->
    </header>
    <!--//header-->

    <div class="docs-wrapper">
      <transition name="fade">
        <div @click="hideNav" id="docs-sidebar" class="docs-sidebar" v-show="showNav">
          <nav id="docs-nav" class="docs-nav navbar">
            <ul class="section-items list-unstyled nav flex-column pb-3">
              <li class="nav-item section-title mt-3">
                <a class="nav-link" href="#intro">
                  <span class="theme-icon-holder mr-2"> <i class="fas fa-question"></i> </span>What is MoDeck?
                </a>
              </li>
              <li class="nav-item section-title mt-3">
                <a class="nav-link" href="#getting-started">
                  <span class="theme-icon-holder mr-2"> <i class="fas fa-play"></i> </span>Getting started
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#make-a-deck">Making an online template</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#desktop-app">The desktop app</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#online-editor">The advanced online editor (Sequence editor)</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#journey-editor">The simple online editor (Journey editor)</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#invite-others">Invite others to use your template</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#previews">Adding preview clips</a>
              </li>

              <li class="nav-item section-title mt-3">
                <a class="nav-link" href="#preparing-mogrts">
                  <span class="theme-icon-holder mr-2"> <i class="fas fa-file"></i> </span>Preparing mogrts
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#modk-commands">Modk commands</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#media-replacement">Media replacement</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#scale-expressions">Useful scaling expressions</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#keyframe-expressions">Useful keyframe expressions</a>
              </li>
              <!--
                <li class="nav-item">
                  <a class="nav-link" href="#audio">Working with audio in mogrts</a>
                </li>
-->
              <li class="nav-item section-title mt-3">
                <a class="nav-link" href="#template-logic">
                  <span class="theme-icon-holder mr-2"> <i class="fas fa-code-branch"></i> </span>Template logic
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#global-options">Global options</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#predefined-sequences">Predefined sequences</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#mogrt-show-hide">Mogrt show/hide conditions</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#option-linking">Linking mogrt options to global options</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#dropdown-mapping">Dropdown mapping</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#mogrt-expressions">Mogrt option expressions</a>
              </li>

              <li class="nav-item section-title mt-3">
                <a class="nav-link" href="#rendering-edits">
                  <span class="theme-icon-holder mr-2"> <i class="fas fa-file-video"></i> </span>Rendering edits
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#rendering-issues">Dealing with render issues</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#multimachine">Multi machine rendering</a>
              </li>

              <li class="nav-item section-title mt-3">
                <a class="nav-link" href="#mogrt-sequences">
                  <span class="theme-icon-holder mr-2"> <i class="fas fa-columns"></i> </span>Mogrt sequences
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#transitioning">Transitioning between mogrts</a>
              </li>

              <li class="nav-item section-title mt-3">
                <a class="nav-link" href="#data-driven">
                  <span class="theme-icon-holder mr-2"> <i class="fas fa-table"></i> </span>Data driven videos
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#data-spreadsheets">Using spreadsheets</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#data-json">Using JSON data</a>
              </li>

              <li class="nav-item section-title mt-3">
                <a class="nav-link" href="#customize">
                  <span class="theme-icon-holder mr-2"> <i class="fas fa-pencil"></i> </span>Editor customization
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#white-labeling">Color schemes and logo</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#magic-links">Magic links</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#notification-emails">Customizing notification emails</a>
              </li>

              <li class="nav-item section-title mt-3">
                <a class="nav-link" href="#modeckapi">
                  <span class="theme-icon-holder mr-2"> <i class="fas fa-code"></i> </span>The MoDeck API
                </a>
              </li>

              <li class="nav-item section-title mt-3">
                <a class="nav-link" href="#base-mogrts">
                  <span class="theme-icon-holder mr-2"> <i class="fas fa-window-minimize"></i> </span>Base mogrts
                </a>
              </li>

              <li class="nav-item section-title mt-3">
                <a class="nav-link" href="#paid-features">
                  <span class="theme-icon-holder mr-2"> <i class="fas fa-star"></i> </span>Pro and Enterprise features
                </a>
              </li>
            </ul>
          </nav>
          <!--//docs-nav-->
        </div>
      </transition>
      <!--//docs-sidebar-->
      <div class="docs-content">
        <div class="container">
          <article class="docs-article">
            <header class="docs-header" id="intro">
              <h1 class="docs-heading">What is MoDeck?</h1>
              <section class="docs-intro docs-section">
                <p>
                  MoDeck is an online platform and After Effects template automation tool, it allows you to let others input data into any exposed
                  properties of your After Effects projects through an easy to use online editor, allowing non-technical users to generate video
                  content using your After Effects templates and have their rendered edits sent straight to their inbox.
                </p>
                <p>
                  MoDeck also makes it incredibly easy for you to
                  <a href="#data-driven">connect properties of your templates to data</a>
                  all through the online editor, allowing you to drive your templates with
                  <a href="#data-spreadsheets">data from a spreadsheet</a> to generate many versions of a video all at once. Take your template
                  automation a step further through the use of the
                  <a href="https://zapier.com/apps/modeck/integrations" target="_blank">MoDeck Zapier integration</a>, where you can plug data into
                  your templates and initiate renders from activity in any of the thousands of websites and applications integrated with Zapier to
                  generate customized video content through your own powerful automated workflows.
                </p>

                <div class="video-button-container video-popup mb-30" style="text-align: center; margin-top: 50px">
                  <a href="https://discord.gg/SFZ4KVSAKd" target="_blank">
                    <div class="video-play">
                      <img height="18" class="fa" src="../assets/img/icons/discord.svg" alt="discord" />
                      <span class="video-text">JOIN US ON DISCORD</span>
                    </div>
                  </a>
                </div>

                <div class="video-button-container video-popup mb-30" style="text-align: center; margin-top: 50px">
                  <div class="video-play" @click="opensilentbox('https://www.youtube.com/watch?v=FbJTpEtV7q4')" style="cursor: pointer">
                    <i class="fa fa-play-circle"></i>
                    <span class="video-text">WATCH THE OVERVIEW/TUTORIAL</span>
                  </div>
                </div>
              </section>
            </header>
          </article>

          <article class="docs-article">
            <header class="docs-header">
              <h1 class="docs-heading" id="getting-started">Getting started</h1>
              <section class="docs-intro docs-section">
                <p>
                  To begin using MoDeck straight away, all you need to do is
                  <a href="https://app.modeck.io/login?signup=1" target="_blank">sign up an account</a>
                  and
                  <a href="https://modeck.io/downloads" target="_blank">download and install the desktop app,</a>
                  then export mogrts from After Effects into your sync folder. Online templates on MoDeck are made entirely from
                  <a href="https://blog.frame.io/2018/11/26/making-mogrts/" target="_blank">mogrt files</a>
                  that the desktop app reads from folders in your sync folder.
                </p>
              </section>
            </header>

            <section id="make-a-deck" class="docs-section">
              <h2 class="section-heading">Making an online template</h2>
              <p>
                An online template on MoDeck is simply a collection of mogrt files, to create an online template, make a folder in your sync folder
                and add your mogrt files to it (Read
                <a href="https://helpx.adobe.com/after-effects/using/creating-motion-graphics-templates.html" target="_blank">this</a>
                and
                <a href="https://blog.frame.io/2018/11/26/mogrt-making-motion-graphics-templates/" target="_blank">this</a>
                for more info about creating mogrts in After Effects), each folder containing 1 or more mogrts instantly becomes an online template
                that will now be synced to your account and accessible by anyone you invite. The name of the folder that contains the mogrts will
                become the name of the template, this folder can be renamed at any point to rename the template, the app will also find any sub
                folders that contain mogrts and these folders will be shown to editors online, so they can browse the same structure you have set up
                in the tempalate folder.
              </p>
              <p>
                Once you've created your first template, open the
                <a href="https://app.modeck.io/" target="_blank">online editor</a>
                to see all of your templates in action and test things out for yourself!
              </p>
              <p>
                You can also add <a href="#previews">preview clips</a> to templates and individual mogrts, allowing your online editors to easily view
                a showcase/preview of what a video made with that template would look like, as well as quickly view the mogrts in motion on hover.
              </p>
              <p>
                To streamline the process of making After Effects changes to your mogrts, you have the option to enable the app setting "Auto export
                mogrts from aeps in sync folder" with this enabled, you can now save aep files into your sync folder, any comps with Essential
                Graphics at the root of the project panel in this aep file will automatically be exported as mogrts into the same folder as the aep,
                making them available in the online editor. As well as looking in the root of the project panel, it will also look in any folders that
                have their comment set to
                <code>modk-export</code>, as well as any folders that are named <code>modk-export</code>. This allows you to quickly adjust mogrts
                without needing to re-export manually every time and without you needing to attempt to import mogrts into AE the alter them, the
                MoDeck app will automatically pull mogrt files out of the aep when you save the project.
              </p>
            </section>
            <!--//section-->

            <section style="padding-top: 74px; margin-top: -39px" id="desktop-app" class="docs-section">
              <h2 class="section-heading">The desktop app</h2>
              <p>
                The desktop app is used to sync your mogrt files to the online editor, list all edits that have been made using your templates, handle
                the rendering and exporting of MoDeck edits and to allow you to invite editors to use your templates. Ensure that your After Effects
                install location is set to the correct path in the settings so that the app can find and integrate with After Effects, you can also
                define a different sync folder location, by default the sync folder will be in your user directory.
              </p>
              <p>
                The app runs in the system tray and is designed to run in the background to always watch for new edits and render and send videos to
                the editor in the background, click the icon in your tray to open the app window, you can also double click the icon to open your sync
                folder.
              </p>
              <figure class="figure docs-figure py-3">
                <img
                  @click="opensilentbox('assets/docs-img/app-breakdown.jpg')"
                  class="figure-img img-fluid"
                  src="assets/docs-img/app-breakdown.jpg"
                  alt
                  style="width: 800px; cursor: pointer"
                />
              </figure>
              <p>
                The app keeps a background instance of After Effects running which it uses to quickly assemble edits and render still previews for
                online editors without interfering with your regular After Effects usage, this background instance of AE will launch whenever the app
                starts up, unless you disable the app setting "Launch background AE on app start". It will also get launched whenever an online editor
                requests a preview or a render. The AE icon to the top right of the app indicates whether this background instance is currently online
                and running, when it is teal it means the instance is running, you can click this icon to close or start the background instance.
              </p>
            </section>
            <!--//section-->

            <section id="online-editor" class="docs-section">
              <h2 class="section-heading">The advanced online editor (Sequence editor)</h2>
              <p>
                When someone is invited to edit your template, they will be able to edit it through the online editor, where they can see all your
                shared mogrts and their editable options to customize the options and create videos. Through the template settings on the desktop app
                you have the option to set whether a template will use the advanced editor or the
                <a href="#journey-editor">simple editor.</a>
              </p>
              <p>
                Upon creating a new edit when using the advanced (sequence) editor, the settings dialog will appear where you can enter a name for
                this new edit, as well as add a spreadsheet or connect to JSON data to
                <a href="#data-driven">create videos from data</a> and select a <a href="#base-mogrts">base mogrt</a> if one has been set by the
                template owner. These settings can be accessed again by clicking the Settings button to the bottom left of the screen.
              </p>
              <p>
                the strip across the bottom of the screen is the timeline, begin by either dragging a mogrt block from the main panel into the
                timeline, or double clicking a mogrt block to add it to the end of the timeline, the panel to the left displays all the settings for
                the currently selected mogrt, where an editor can change options, input text, upload media etc.
              </p>
              <p>
                To view a preview of your changes, click the preview tab above the mogrt browser section, new previews will be fetched every time you
                make a change to an option, this can be disabled through the template settings on the app. The desktop app must be running on the
                template owners pc to be able to get previews. Be sure to define which frames to send for previews by using the
                <a href="#modk-commands">modk-preview command.</a>
              </p>
              <figure class="figure docs-figure py-3">
                <img
                  @click="opensilentbox('assets/docs-img/editor-breakdown.jpg')"
                  class="figure-img img-fluid"
                  src="assets/docs-img/editor-breakdown.jpg"
                  alt
                  style="width: 100%; cursor: pointer"
                />
              </figure>
            </section>
            <!--//section-->

            <section id="journey-editor" class="docs-section">
              <h2 class="section-heading">The simple online editor (Journey editor)</h2>
              <p>Through the template settings on desktop app, you can define whether a template will use the simple editor.</p>
              <p>
                The simple (journey) editor provides online editors with a much more basic way to create videos using your templates, online editors
                don't save edits, and it does not have a timeline where you drag and drop mogrts, instead, they are guided option by option through
                all the editable properties of your template, making the editing process far more guided and straightforward.
              </p>
              <p>
                A preview image is shown to the right of each option, this will automatically update to reflect the editors changes as they edit the
                options, when setting up a mogrt in the After Effects essential graphics panel, you can define which frame is used as a preview frame
                for each option by making sure that the
                <a href="#modk-commands">modk-preview</a> comment appears before that option in the essential graphics panel, drag it before any
                options you want this preview to be used for in the editing journey.
              </p>
              <p>
                If you add a regular comment in the Essential Graphics panel, on the journey editor it will appear as an additional description for
                the option directly after that comment, allowing you to add additional details and notes about that editable property that will appear
                when an editor hovers on the info icon above an option.
              </p>
              <figure class="figure docs-figure py-3">
                <img
                  @click="opensilentbox('assets/docs-img/journey.jpg')"
                  class="figure-img img-fluid"
                  src="assets/docs-img/journey.jpg"
                  alt
                  style="width: 100%; cursor: pointer"
                />
              </figure>
            </section>
            <!--//section-->

            <section id="invite-others" class="docs-section">
              <h2 class="section-heading">Invite others to use your template</h2>
              <p>
                Through the desktop app you can invite others to access your template. To invite someone, select the template from the drop-down at
                the top of the app and then clicking the user management tab. You can add multiple email addresses by separating them by commas. Any
                editor you've invited will be notified via email and can now make videos by arranging the mogrts of the template in
                <a href="#mogrt-sequences">sequence using the online editor</a>. any properties available in the mogrt can be edited through the
                online editor, with some <a href="#preparing-mogrts">additional features</a> that enhance the capabilities of these mogrts.
              </p>
              <p>Be sure to add hello@modeck.io to your email contacts to ensure that all notification mails are received correctly.</p>
            </section>
            <!--//section-->

            <section class="docs-section" id="previews">
              <h2 class="section-heading">Adding preview clips</h2>
              <figure class="figure docs-figure py-3">
                <div style="width: 50%; float: left; padding: 10px">
                  <img
                    @click="opensilentbox('assets/docs-img/showcase.jpg')"
                    class="figure-img img-fluid"
                    src="assets/docs-img/showcase.jpg"
                    alt
                    style="width: 100%; cursor: pointer"
                  />
                </div>
                <div style="width: 50%; float: left; padding: 10px">
                  <img
                    @click="opensilentbox('assets/docs-img/mogrt_preview.gif')"
                    class="figure-img img-fluid"
                    src="assets/docs-img/mogrt_preview.gif"
                    alt
                    style="width: 100%; cursor: pointer"
                  />
                </div>
              </figure>
              <p>
                It is often helpful to provide your online editors with preview/showcase clips to give them an idea of what their rendered videos can
                look like before they've started editing, to include a video preview of a mogrt first ensure that you are using After Effects version
                24.2 or later, then simply enable the option <code>Include video preview</code> in the mogrt export panel when exporting your mogrt
                from the Essential Graphics panel.
              </p>
              <p>
                To add a preview/showcase video for the entire template, add an mp4 with the same filename as the template into the root of your sync
                folder, alongside the template folders, once added a play button will appear next to the name of that template, clicking this opens
                the video player to show that preview/showcase online.
              </p>
            </section>
            <!--//section-->
          </article>

          <article class="docs-article">
            <header class="docs-header">
              <h1 class="docs-heading" id="preparing-mogrts">Preparing mogrts</h1>
              <section class="docs-intro docs-section">
                <p>
                  MoDeck uses regular
                  <a href="https://blog.frame.io/2018/11/26/making-mogrts/" target="_blank">mogrt files</a>
                  that have been
                  <a href="https://youtu.be/JOoAq_xnviw?t=73" target="_blank">created with After Effects,</a>
                  there is nothing further you need to do to a mogrt file to make it usable in MoDeck, however there are extra MoDeck specific
                  features that can be added to your mogrts to fully utilize MoDecks capabilities and make more powerful template systems.
                </p>
              </section>
              <!--//docs-intro-->
            </header>

            <section class="docs-section" id="modk-commands">
              <h2 class="section-heading">Modk commands</h2>
              <p>
                Extra features are added to mogrts in the form of modk commands, these are applied by adding particular properties to your Essential
                Graphics panel and naming them in particular ways as presented in the table below. Many commands are applied by adding a comment to
                Essential Graphics and naming it with one of the commands, to add a comment, select the "Add Formatting" drop-down on the bottom left
                of the Essential Graphics panel and select "Add Comment."
              </p>
              <figure class="figure docs-figure py-3">
                <img
                  @click="opensilentbox('assets/docs-img/comments.jpg')"
                  class="figure-img img-fluid"
                  src="assets/docs-img/comments.jpg"
                  alt
                  style="width: 529px; cursor: pointer"
                />
              </figure>

              <div class="table-responsive my-4">
                <table class="table table-bordered table-dark" style="background-color: #747f94">
                  <thead>
                    <tr>
                      <th style="width: 240px" scope="col">Command</th>
                      <th scope="col">Explanation</th>
                      <th scope="col">Example</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        modk-preview-[Optional name]<i class="fas fa-clone copy" text="modk-preview-[Optional name]" @click="copyText($event)"></i>
                      </td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added as a comment or as a comp marker.</strong>
                        Set the value of this comment to the frame number that you would like to be rendered when an editor fetches a preview of this
                        mogrt on the online editor, alternatively add a comp marker to your mogrts main comp with it's comment set to
                        <code style="background: #5d677b; color: #ffffff">modk-preview-[Optional name]</code>, if this command is not present as a
                        marker or essential graphics comment then the default frame sent will be the mid-point of the mogrts duration. You can
                        optionally add a name for this frame (replace <code style="background: #5d677b; color: #ffffff">[Optional name]</code>) and
                        add this command to essential graphics multiple times with different names and frame values to make it possible for the editor
                        to cycle through multiple still frames in order to preview their changes at multiple times in the mogrt.
                      </td>
                      <td style="text-align: center; font-size: 30px">
                        <i @click="opensilentbox('assets/docs-img/example_preview.jpg')" style="cursor: pointer" class="fas fa-eye example"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>modk-render<i class="fas fa-clone copy" text="modk-render" @click="copyText($event)"></i></td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added as a comp marker.</strong>
                        This defines where still frames will be rendered from if the template is set to output jpg or png, add markers to your main
                        comp with their comment to <code style="background: #5d677b; color: #ffffff">modk-render</code> and these frames are what will
                        be rendered as stills, a still frame will be rendered for every
                        <code style="background: #5d677b; color: #ffffff">modk-render</code> marker. If you would like to define a preview frame and a
                        render frame at the same time in the mogrt, you can add both
                        <code style="background: #5d677b; color: #ffffff">modk-preview</code> and
                        <code style="background: #5d677b; color: #ffffff">modk-render</code> in the same marker comment, but separated by comma, eg.
                        <code style="background: #5d677b; color: #ffffff">modk-render, modk-preview-Name</code>.
                      </td>
                      <td style="text-align: center; font-size: 30px"></td>
                    </tr>
                    <tr>
                      <td>modk-setDuration<i class="fas fa-clone copy" text="modk-setDuration" @click="copyText($event)"></i></td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added as a slider.</strong>
                        This essentially allows you to control the duration of this mogrts main comp with an expression in After Effects, any value
                        that the slider linked to this essential graphics property is set to will become the duration (in seconds) that this mogrts
                        main comp gets set to when the edit is assembled, meaning you can add an expression to the slider and the result of that
                        expression will set the duration of this mogrt, A handy use for this is to use an expression to link this property to the
                        duration of a footage item in the comp (eg.
                        <code style="background: #5d677b; color: #ffffff">thisComp.layer(1).source.duration</code>), The duration of this comp will
                        now always match the duration of the footage item, then make that footage item replaceable by dragging it into Essential
                        Graphics, so when rendered, this mogrt will always be set to whatever the duration of that replaceable footage item is. Also
                        link this to another editable property of this mogrt to make the duration directly customizable by editors online (eg. you can
                        make a dropdown menu with several predefined durations for an editor to choose from, through expressions this dropdown can
                        drive the slider that is linked to modk-setDuration). Since this duration must be calculated by the expression within After
                        Effects, the mogrts duration as it appears on the timeline in the online editor can't be calculated in real time, the duration
                        of the mogrt as it is shown in the timeline online will get recalculated whenever the preview is updated. If you are using
                        protected regions in this mogrt, then rather use modk-setStretch to change it's duration.
                      </td>
                      <td style="text-align: center; font-size: 30px">
                        <i @click="opensilentbox('assets/docs-img/example_setduration.jpg')" style="cursor: pointer" class="fas fa-eye example"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>modk-setStretch<i class="fas fa-clone copy" text="modk-setStretch" @click="copyText($event)"></i></td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added as a slider.</strong>
                        Same as modk-setDuration, but instead of setting the duration of the mogrt comp, it will rather stretch this mogrts layer in
                        <a href="#mogrt-sequences">the sequence</a> to the new duration, this is necessary if you are using protected regions in this
                        mogrt.
                        <a href="https://helpx.adobe.com/after-effects/using/responsive-design.html" target="_blank"
                          >(Learn more about protected regions and responsive design in After Effects)</a
                        >
                      </td>
                      <td style="text-align: center; font-size: 30px">
                        <i @click="opensilentbox('assets/docs-img/example_setduration.jpg')" style="cursor: pointer" class="fas fa-eye example"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>modk-dimensions<i class="fas fa-clone copy" text="modk-dimensions" @click="copyText($event)"></i></td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added as a dropdown.</strong>
                        This allows you to define multiple different resolutions that this mogrt can be rendered at, when requesting a render online,
                        your users will be able to select which resolutions they would like to be rendered, they will then receive multiple renders
                        for all their selected resolutions. This is how to set up dimensions: When building your mogrt, add a Dropdown Menu Control in
                        your comp, add each of the resolutions you would like into the Dropdown Menu Control, each of these items in the Dropdown Menu
                        Control must be named with the resolution values separated by "x" then a dash, then the name of this resolution, for example
                        <code style="background: #5d677b; color: #ffffff">1080x1920-Story</code>
                        Now add this Dropdown Menu Control to Essential Graphics for your mogrt and name it "modk-dimensions", when this mogrt is
                        rendered, it's comp dimensions will be set using the dimensions specified in the dropdown, use expressions in your mogrts main
                        comp to make your layout responsive to different sizes
                        <a href="https://youtu.be/CEXFIPPAR4w" target="_blank">(learn more about adaptive design in AE here)</a>
                        The mogrts dimensions dropdown value will also be set to the relevant dimensions option, meaning you are also able to use
                        expressions to link aspects of your layout to the dropdown value, to alter layers based on which dropdown dimension is
                        selected. Note that the list of dimensions selectable on the online editor will only show dimensions that are shared across
                        all mogrts currently in the sequence, so make sure you add matching resolution options across all mogrts in a template.
                      </td>
                      <td style="text-align: center; font-size: 30px">
                        <i @click="opensilentbox('assets/docs-img/example_dimensions.jpg')" style="cursor: pointer" class="fas fa-eye example"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>modk-durationEditable<i class="fas fa-clone copy" text="modk-durationEditable" @click="copyText($event)"></i></td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added as a comment.</strong>
                        Defines if the duration of this mogrt can be edited in the online editor, if this command is not present, no drag handle
                        appears on mogrt in the editor, if present, then a drag handle appears in editor and the duration number appears as an option.
                        The maximum duration that this mogrt can be extended to in the online editor will be the duration of the comp, you can define
                        what the initial duration of this mogrt will be when placed on the timeline by adding that duration (in frames) to the comment
                        value. This command will not apply if this mogrt is also using modk-setDuration of modk-setStretch.
                      </td>
                      <td style="text-align: center; font-size: 30px">
                        <i @click="opensilentbox('assets/docs-img/example_durationedit.jpg')" style="cursor: pointer" class="fas fa-eye example"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>modk-stretchEditable<i class="fas fa-clone copy" text="modk-stretchEditable" @click="copyText($event)"></i></td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added as a comment.</strong>
                        Same as modk-durationEditable, but when the edit is assembled in AE, instead of changing the duration of the mogrt comp, it
                        will rather stretch this mogrts layer in
                        <a href="#mogrt-sequences">the sequence</a> to the new duration, this is necessary if you are using protected regions in this
                        mogrt.
                        <a href="https://helpx.adobe.com/after-effects/using/responsive-design.html" target="_blank"
                          >(Learn more about protected regions and responsive design in After Effects)</a
                        >
                      </td>
                      <td style="text-align: center; font-size: 30px">
                        <!--<i
                          @click="
                            opensilentbox(
                              'assets/docs-img/example_durationedit.jpg'
                            )
                          "
                          style="cursor: pointer"
                          class="fas fa-eye example"
                        ></i>-->
                      </td>
                    </tr>
                    <tr>
                      <td>modk-startEditable<i class="fas fa-clone copy" text="modk-startEditable" @click="copyText($event)"></i></td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added as a comment.</strong>
                        Defines if the start time of this mogrt can be extended, allowing editors to add a gap before this mogrt starts, if this
                        command is present, then a drag handle will appear on the left of this mogrt in the timeline on editor and the start delay
                        number appears as an option. You can define what the initial start delay of this mogrt will be when placed on the timeline by
                        adding that gap duration (in frames) to the comment value.
                      </td>
                      <td style="text-align: center; font-size: 30px">
                        <i @click="opensilentbox('assets/docs-img/example_startedit.jpg')" style="cursor: pointer" class="fas fa-eye example"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>*[option name]<i class="fas fa-clone copy" text="*[option name]" @click="copyText($event)"></i></td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added to text input, media replacement input or dropdown.</strong>
                        Add a * at the beginning of an option name to make this a required field, on the online editor, when a text input is required,
                        it will default to being blank and the editor will not be able to preview or request renders until it has been filled. Note
                        that this * should be added before "multiline-" or "limit-" if using these as well.
                      </td>
                      <td style="text-align: center; font-size: 30px">
                        <!--<i @click="
                          opensilentbox(
                            'assets/docs-img/example_multiline.jpg'
                          )
                        "
                        style="cursor: pointer" class="fas fa-eye example"></i>-->
                      </td>
                    </tr>
                    <tr>
                      <td>multiline-[option name]<i class="fas fa-clone copy" text="multiline-[option name]" @click="copyText($event)"></i></td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added as text input.</strong>
                        Add "multiline-" at the beginning of a text fields option name to allow a user to edit this text with multiple lines in the
                        online editor.
                      </td>
                      <td style="text-align: center; font-size: 30px">
                        <i @click="opensilentbox('assets/docs-img/example_multiline.jpg')" style="cursor: pointer" class="fas fa-eye example"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        limit-[number]-[option name]<i class="fas fa-clone copy" text="limit-[number]-[option name]" @click="copyText($event)"></i>
                      </td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added as text input.</strong>
                        Add "limit-" followed by a text length number limit, followed by another dash at the beginning of a text fields option name to
                        limit the number of characters allowed in this text.
                      </td>
                      <td style="text-align: center; font-size: 30px">
                        <i @click="opensilentbox('assets/docs-img/example_limit.jpg')" style="cursor: pointer" class="fas fa-eye example"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>modk-overlap<i class="fas fa-clone copy" text="modk-overlap" @click="copyText($event)"></i></td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added as a comment.</strong>
                        Make this mogrt start sooner so that it overlaps the previous mogrt in
                        <a href="#mogrt-sequences">the sequence</a>, useful for when this mogrt has an introduction animation that animates on
                        transparency and you would like that animation to play on top of the previous mogrt slightly before the previous mogrt has
                        finished. Set the value of this comment to the number of frames it should overlap the previous. (Note that this will shorten
                        the overall length of the edit)
                      </td>
                      <td style="text-align: center; font-size: 30px">
                        <i @click="opensilentbox('assets/docs-img/example_overlap.jpg')" style="cursor: pointer" class="fas fa-eye example"></i>
                      </td>
                    </tr>
                    <!--<tr>
                      <td>modk-description<i class="fas fa-clone copy" text="modk-description" @click="copyText($event)"></i></td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added as a comment.</strong>
                        Set the value of this comment to a description of this mogrt, when using AI to automatically generate edits, this descrition
                        is fed into the AI to help it understand how this mogrt would be used in an edit. Be sure to describe what this mogrt looks
                        like and what it would be used for.
                      </td>
                      <td style="text-align: center; font-size: 30px">
                      </td>
                    </tr>-->
                    <tr>
                      <td>direct-[option name]<i class="fas fa-clone copy" text="direct-[option name]" @click="copyText($event)"></i></td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added on any option.</strong>
                        Add "direct-" at the beginning of any options name, when the edit is assembled in After Effects, this properties new value
                        will not only be set by changing the mogrts properties from outside of the mogrt comp, but the actual property associated with
                        this mogrt option will be set directly, this is useful to circumvent a limitation of Essential Properties, where audio cannot
                        be altered from outside a mogrt, for example if you want to make audio volume editable, use this command and that audio volume
                        change will be applied directly to the volume value within the mogrt comp and not just to the mogrts properties outside the
                        comp.
                      </td>
                      <td style="text-align: center; font-size: 30px"></td>
                    </tr>
                    <tr>
                      <td>modk-transcribe<i class="fas fa-clone copy" text="modk-transcribe" @click="copyText($event)"></i></td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added on any comment.</strong>
                        This allows editors to transcribe audible speech in a mogrt and have the words appear as markers on the mogrt in the online
                        timeline, making it easier to time edits according to voice. If you make the audio layer containing the speech replaceable,
                        then the online editor will be able to add their own audio, and have that speech transcribed. Add this command to a mogrt and
                        an option will appear on the online editor with a button to fetch a transcription, when pressed the MoDeck app will export
                        audio of the mogrt then transcribe that audio and send the transcription to the online editor, which will then appear as
                        markers on the editor timeline. If you would only like certain AE layers in the mogrt to be transcribed, then add the layer
                        index numbers of those layers to the comment value, separated by commas (eg. if there is music in the mogrt as well as speech,
                        you can add the layer index of the speech audio layer, and the music will not be included in the transcription) if the comment
                        value of the command is left blank then all audio in the mogrt will be transcribed.
                      </td>
                      <td style="text-align: center; font-size: 30px"></td>
                    </tr>
                    <tr>
                      <td>modk-base<i class="fas fa-clone copy" text="modk-base" @click="copyText($event)"></i></td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added as a comment.</strong>
                        If this comment is present in the mogrt, this mogrt can be used as a base mogrt,
                        <a href="#base-mogrts">(more about base mogrts here)</a>
                        set the comment value to "always" (without quotes) if you would like this to always be the base mogrt for all edits make with
                        this template, ie online editors won't be able to set the base mogrt. Add the word "stretch" (without quotes) and the duration
                        of the base mogrt will be stretched to match the edit length as opposed to having the comp duration change, which is necessary
                        if using protected regions in this mogrt
                        <a href="https://helpx.adobe.com/after-effects/using/responsive-design.html" target="_blank"
                          >(Learn more about protected regions and responsive design in After Effects)</a
                        >
                        You can also add the word "fixed" (without quotes) if you would like the base mogrt to always retain its duration, all edits
                        with this base mogrt will now always be set to the duration of the base mogrt, no matter if the mogrt sequence is longer than
                        the base mogrt or shorter.
                      </td>
                      <td style="text-align: center; font-size: 30px">
                        <i @click="opensilentbox('assets/docs-img/example_base.jpg')" style="cursor: pointer" class="fas fa-eye example"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>modk-index<i class="fas fa-clone copy" text="modk-index" @click="copyText($event)"></i></td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added as a slider.</strong>
                        The value of this slider property will be set to the index of this mogrt in the edits
                        <a href="#mogrt-sequences">sequence</a>, ie. the number that this mogrt appears in the sequence order, the first mogrt in the
                        sequence being 0, and if it is a base mogrt it will have an index of -1. Use expressions to link properties to this value in
                        your AE comps to have things behave differently depending on the order they're arranged in the MoDeck edit. For example, use
                        an expression to determine whether this mogrt is being used as the first mogrt in the sequence(index of 0) and have the
                        introduction of this mogrt animate accordingly.
                      </td>
                      <td style="text-align: center; font-size: 30px">
                        <i @click="opensilentbox('assets/docs-img/example_index.jpg')" style="cursor: pointer" class="fas fa-eye example"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>modk-count<i class="fas fa-clone copy" text="modk-count" @click="copyText($event)"></i></td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added as a slider.</strong>
                        The value of this slider property will be set to the total number of mogrts in the
                        <a href="#mogrt-sequences">sequence</a> created by the editor, use expressions in your AE comps to link to this to have
                        properties bahave differently depending on how many mogrts are in the sequence. For example, use this to determine if a mogrt
                        is being used as the last mogrt in a sequence by using it in conjunction with modk-index, if index matches count minus 1
                        (subtract 1 since the indexes start at 0), then this is the last mogrt in the sequence.
                      </td>
                      <td style="text-align: center; font-size: 30px">
                        <i @click="opensilentbox('assets/docs-img/example_count.jpg')" style="cursor: pointer" class="fas fa-eye example"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>modk-editName<i class="fas fa-clone copy" text="modk-editName" @click="copyText($event)"></i></td>
                      <td>
                        <strong style="color: #fff; font-weight: bold">Added as text input.</strong>
                        The value of this property will be set to the name of the edit, as defined by the online editor.
                      </td>
                      <td style="text-align: center; font-size: 30px"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--//table-responsive-->
            </section>
            <!--//section-->

            <section class="docs-section" id="media-replacement">
              <h2 class="section-heading">Media replacement</h2>
              <p>
                To make a video or image in your After Effects project replaceable, simply drag that media layer into your Essential Graphics panel
                and give this option a name.
              </p>
              <p>
                When this media item is replaced, the new media item will be cropped within the bounds of the original media item, and it will be
                scaled within those bounds according to the "Default Scaling" option that can be found to the right of this option in the Essential
                Graphics panel.
              </p>
              <p>
                Note that you can also make a comp layer replaceable by dragging it into your Essential Graphics panel, meaning that comp will be
                replaced by media uploaded online, this is also helpful if you would like to make an audio layer replaceable; since Essential Graphics
                prevents you from adding an audio layer, just precompose that audio layer and drag that precomp into Essential Graphics.
              </p>
              <p>
                When setting replacement media online, editors can either upload their media through the editor, or enter a filename or URL. If a
                filename has been entered, then the app will look for this file within the <code>MoDeck Sync\modk-data\User media</code> folder, you
                can add extra folders for it to look in by using the setting "Add additional media folders" found in the template settings on the app.
                Editors online are also able to enter filenames that contain slashes to have it look within subfolders of each folder it checks (These
                must be forward slashes "/")
              </p>
              <p>
                If the user has input a URL, the app will detect that it is a URL and attempt to download the media from that URL, this also supports
                URLs to various video platforms, such as YouTube, Vimeo, Instagram etc.
              </p>
              <p>
                If the user has uploaded their media through the online editor, that media will be uploaded and stored online, and the app will
                download it and place it into the
                <code>MoDeck Sync\modk-data\User media\[EDITORS EMAIL]</code>
                folder.
              </p>
              <p>
                Editors are also able to generate speech from text in order to have that synthesized speech file replace the media in the mogrt, this
                is done by clicking the button "Generate speech audio file from text" at the bottom of the media selection dialog, a voice can then be
                selected and text entered which will become speech, the text can also be driven by data, meaning this text can be dynamically taken
                from an API or spreadsheet.
              </p>
            </section>
            <!--//section-->

            <section class="docs-section" id="scale-expressions">
              <h2 class="section-heading">Useful scaling expressions</h2>
              <p>Place one of these expressions in the scale property of the layer to dynamically resize it.</p>
              <p>STRETCH to fill entire comp (Will not maintain aspect ratio)</p>
              <code style="display: inline-block; margin-bottom: 30px; margin-left: 20px"
                ><span style="display: block">layerSize = sourceRectAtTime(time);</span>
                <span style="display: block">sizeW = 100*thisComp.width/layerSize.width;</span>
                <span style="display: block">sizeH = 100*thisComp.height/layerSize.height;</span>
                <span style="display: block">[sizeW, sizeH]</span> </code
              ><i
                class="fas fa-clone copy"
                text="layerSize = sourceRectAtTime(time);
sizeW = 100*thisComp.width/layerSize.width;
sizeH = 100*thisComp.height/layerSize.height;
[sizeW, sizeH]"
                @click="copyText($event)"
              ></i>
              <p>SCALE to always fill comp width (Will maintain aspect ratio)</p>
              <code style="display: inline-block; margin-bottom: 30px; margin-left: 20px"
                ><span style="display: block">layerSize = sourceRectAtTime(time);</span>
                <span style="display: block">sizeW = 100*thisComp.width/layerSize.width;</span>
                <span style="display: block">[sizeW, sizeW]</span> </code
              ><i
                class="fas fa-clone copy"
                text="layerSize = sourceRectAtTime(time);
sizeW = 100*thisComp.width/layerSize.width;
[sizeW, sizeW]"
                @click="copyText($event)"
              ></i>
              <p>SCALE to always fill comp height (Will maintain aspect ratio)</p>
              <code style="display: inline-block; margin-bottom: 30px; margin-left: 20px"
                ><span style="display: block">layerSize = sourceRectAtTime(time);</span>
                <span style="display: block">sizeH = 100*thisComp.height/layerSize.height;</span>
                <span style="display: block">[sizeH, sizeH]</span> </code
              ><i
                class="fas fa-clone copy"
                text="layerSize = sourceRectAtTime(time);
sizeH = 100*thisComp.height/layerSize.height;
[sizeH, sizeH]"
                @click="copyText($event)"
              ></i>
              <p>SCALE to FILL comp (no gaps)</p>
              <code style="display: inline-block; margin-bottom: 30px; margin-left: 20px"
                ><span style="display: block">layerSize = sourceRectAtTime(time);</span>
                <span style="display: block">cw = thisComp.width;</span>
                <span style="display: block">ch = thisComp.height;</span>
                <span style="display: block">lw = layerSize.width;</span>
                <span style="display: block">lh = layerSize.height;</span>
                <span style="display: block">rw = cw / lw;</span>
                <span style="display: block">rh = ch / lh;</span>
                <span style="display: block">l = lw * rh;</span>
                <span style="display: block">if (l > cw) {</span>
                <span style="display: block">&nbsp;&nbsp;s = 100 * rh;</span>
                <span style="display: block">} else {</span>
                <span style="display: block">&nbsp;&nbsp;s = 100 * rw;</span>
                <span style="display: block">}</span>
                <span style="display: block">[s,s];</span> </code
              ><i
                class="fas fa-clone copy"
                text="layerSize = sourceRectAtTime(time);
cw = thisComp.width;
ch = thisComp.height;
lw = layerSize.width;
lh = layerSize.height;
rw = cw / lw;
rh = ch / lh;
l = lw * rh;
if (l > cw) {
  s = 100 * rh;
} else {
  s = 100 * rw;
}
[s,s];"
                @click="copyText($event)"
              ></i>
              <p>SCALE to FIT comp (possible gaps on top and bottom or left and right)</p>
              <code style="display: inline-block; margin-bottom: 30px; margin-left: 20px"
                ><span style="display: block">layerSize = sourceRectAtTime(time);</span>
                <span style="display: block">cw = thisComp.width;</span>
                <span style="display: block">ch = thisComp.height;</span>
                <span style="display: block">lw = layerSize.width;</span>
                <span style="display: block">lh = layerSize.height;</span>
                <span style="display: block">rw = cw / lw;</span>
                <span style="display: block">rh = ch / lh;</span>
                <span style="display: block">l = lw * rh;</span>
                <span style="display: block">if (l > cw) {</span>
                <span style="display: block">&nbsp;&nbsp;s = 100 * rw;</span>
                <span style="display: block">} else {</span>
                <span style="display: block">&nbsp;&nbsp;s = 100 * rh;</span>
                <span style="display: block">}</span>
                <span style="display: block">[s,s];</span> </code
              ><i
                class="fas fa-clone copy"
                text="layerSize = sourceRectAtTime(time);
cw = thisComp.width;
ch = thisComp.height;
lw = layerSize.width;
lh = layerSize.height;
rw = cw / lw;
rh = ch / lh;
l = lw * rh;
if (l > cw) {
  s = 100 * rw;
} else {
  s = 100 * rh;
}
[s,s];"
                @click="copyText($event)"
              ></i>
              <p>LIMIT width</p>
              <code style="display: inline-block; margin-bottom: 30px; margin-left: 20px"
                ><span style="display: block">let limitW = 500;</span>
                <span style="display: block">let s = sourceRectAtTime();</span>
                <span style="display: block">let sc = Math.min(100 * limitW / s.width, value[0]);</span>
                <span style="display: block">[sc, sc];</span></code
              ><i
                class="fas fa-clone copy"
                text="let limitW = 500;
let s = sourceRectAtTime();
let sc = Math.min(100 * limitW / s.width, value[0]);
[sc, sc];"
                @click="copyText($event)"
              ></i>
              <p>LIMIT height</p>
              <code style="display: inline-block; margin-bottom: 30px; margin-left: 20px"
                ><span style="display: block">let limitH = 500;</span>
                <span style="display: block">let s = sourceRectAtTime();</span>
                <span style="display: block">let sc = Math.min(100 * limitH / s.height, value[1]);</span>
                <span style="display: block">[sc, sc];</span></code
              ><i
                class="fas fa-clone copy"
                text="let limitH = 800;
let s = sourceRectAtTime();
let sc = Math.min(100 * limitH / s.width, value[0]);
[sc, sc];"
                @click="copyText($event)"
              ></i>
              <p>LIMIT width and height</p>
              <code style="display: inline-block; margin-bottom: 30px; margin-left: 20px"
                ><span style="display: block">let limitW = 500;</span>
                <span style="display: block">let limitH = 500;</span>
                <span style="display: block">let s = sourceRectAtTime();</span>
                <span style="display: block">let sc = Math.min(100 * limitW / s.width, 100 * limitH / s.height, value[0]);</span>
                <span style="display: block">[sc, sc];</span></code
              >
              <i
                class="fas fa-clone copy"
                text="let limitW = 500;
let limitH = 500;
let s = sourceRectAtTime();
let sc = Math.min(100 * limitW / s.width, 100 * limitH / s.height, value[0]);
[sc, sc];"
                @click="copyText($event)"
              ></i>
            </section>
            <!--//section-->

            <section class="docs-section" id="keyframe-expressions">
              <h2 class="section-heading">Useful keyframe expressions</h2>
              <p>
                Place one of these expressions in a property that has keyframes to dynamically change the timing of the keyframes.
                <a href="https://youtu.be/i4rilThiRDE" target="_blank">Also check out this tutorial about retiming keyframes for templates.</a>
              </p>
              <p>
                Make keyframes always animate at the end of the comp, whatever the duration is (useful for fading music or animating elements away at
                the end of a comp that is using
                <a href="#modk-commands">modk-durationEditable or modk-setDuration</a>)
              </p>
              <code style="display: inline-block; margin-bottom: 30px; margin-left: 20px"
                ><span style="display: block">var dur = thisComp.duration;</span>
                <span style="display: block">var lastKeyTime = thisProperty.key(thisProperty.numKeys).time;</span>
                <span style="display: block">valueAtTime(time - dur + lastKeyTime);</span> </code
              ><i
                class="fas fa-clone copy"
                text="var dur = thisComp.duration;
var lastKeyTime = thisProperty.key(thisProperty.numKeys).time;
valueAtTime(time - dur + lastKeyTime);"
                @click="copyText($event)"
              ></i>
              <p>Stretch keyframes so the animation always happens over the full duration of this comp, whatever the duration is</p>
              <code style="display: inline-block; margin-bottom: 30px; margin-left: 20px"
                ><span style="display: block">var dur = thisComp.duration;</span>
                <span style="display: block">var lastKeyTime = thisProperty.key(thisProperty.numKeys).time;</span>
                <span style="display: block">valueAtTime(time / dur * lastKeyTime);</span> </code
              ><i
                class="fas fa-clone copy"
                text="var dur = thisComp.duration;
var lastKeyTime = thisProperty.key(thisProperty.numKeys).time;
valueAtTime(time / dur * lastKeyTime);"
                @click="copyText($event)"
              ></i>
            </section>
            <!--//section-->
            <!--
            <section class="docs-section" id="audio">
              <h2 class="section-heading">Working with audio in mogrts</h2>
              <p>
                If you have music playing in your mogrt which has a dynamic duration (using
                <a href="#modk-commands">modk-durationEditable or modk-setDuration</a>) how do you make this music fade out at the end of the mogrt,
                no matter what the mogrts duration has been set to by the editor? This is quite simple, all you need to do is add keyframes to the
                Audio Levels on the music layer to fade it out, then add an expression to the Audio Levels to re-time those keyframes to always happen
                at the end of the current comps duration, <a href="#keyframe-expressions">as explained here,</a> since
                <a href="#modk-commands">modk-durationEditable and modk-setDuration</a> alter the comps duration, the audio will now always fade at
                the end of the mogrt, even if the duration was changed by an editor.
              </p>
              <p>
                What if you would like music playing underneath the entire sequence of mogrts, for the full duration of any edit made with one of your
                templates? This can be done using a <a href="#base-mogrts">base mogrt.</a> Create a mogrt that only contains the audio with the above
                mentioned expression applied to the Audio Levels, then make this mogrt a base mogrt using the
                <a href="#modk-commands">modk-base command,</a> base mogrts play for the full duration of a mogrt sequence and adapt their duration to
                the full duration of the sequence, meaning the comp will change duration according to the mogrt sequence length, thus making the fade
                out always happen at the end of the edit. When making an edit online, just be sure to set the base mogrt dropdown in the edits
                settings to use you music mogrt as the base mogrt.
              </p>
              <p>
                // Doesnt actually work :(
                If you are wanting to provide more advanced audio control in your mogrts, such as providing a checkbox to your editors to disable
                certain audio layers online, then there is a quirk with mogrts to be aware of and a very simple solution; adding an audio layers Audio
                Levels property to Essential Graphics is not currently supported, and even applying expression to the Audio Levels property will not
                affect the Audio Levels if the expression is linked to properties driven by any Essential Properties, meaning it does not appear
                possible to drive any audio levels with Essential Graphics properties, but what you can do is use a standard effect in After Effects
                call Stereo Mixer, drop this effect onto an audio layer and it allows you to change the volume of the audio, you can now add
                expressions to the Stereo Mixers volume controls to have them change depending on a checkbox that you've added to Essential Graphics,
                just be sure to add you expression to both the left and right channels in the Stereo Mixer effect. You could even use this to provide
                your editors with a choice of which music track to use by connecting several different audio layers to a dropdown control.
              </p>
            </section>
-->
          </article>

          <article class="docs-article">
            <header class="docs-header">
              <h1 class="docs-heading" id="template-logic">Template logic</h1>
              <section class="docs-intro docs-section">
                <p>
                  By default, users of your online template will create videos by dragging and dropping mogrts onto the timeline to form a sequence,
                  and adjust each editable property within the mogrts, but this is not always desirable as it's often important to have more
                  'guardrails' in place; it's generally better to give your users less fine-grained control and rather have them choose some basic,
                  overarching options that dictate the sequence of mogrts and their values based on a set of predefined conditions, this is where
                  template logic comes in.
                </p>
                <figure class="figure docs-figure py-3">
                  <img
                    @click="opensilentbox('assets/docs-img/logic-breakdown.jpg')"
                    class="figure-img img-fluid"
                    src="assets/docs-img/logic-breakdown.jpg"
                    alt
                    style="width: 100%; cursor: pointer"
                  />
                </figure>
                <p>
                  Access the template logic settings by selecting the "Template logic" tab in the app, this page consists of 2 main sections, "Global
                  options" and "Mogrts/Predefined sequences." The main idea of template logic is that it allows you to define a set of global options;
                  options that are always present on the online editor, no matter what is in the sequence of the editor, you're then able to link any
                  number of options within any number of mogrts to a single global option, as well as set up conditions to show/hide mogrts based on
                  the values of the global options. You are also able to set up a predefined sequence and attach show/hide conditions to each mogrt in
                  the sequence, meaning editors do not have direct control of the sequence, but rather make changes to the global options which then
                  affect what's in the sequence based on your conditional logic.
                </p>
                <p>
                  Global options and predefined sequences also work on the
                  <a href="#journey-editor">journey editor</a>, when global options are added and the journey editor is enabled, the global options
                  will be the first options to be shown to the user, then if a predefined sequence has been set up, the options for each item in the
                  sequence will be shown one after the other, show/hide conditions also apply; when a mogrt is set to be hidden, its options will no
                  longer appear in the journey.
                </p>
              </section>
            </header>
            <!--//docs-intro-->

            <section class="docs-section" id="global-options">
              <h2 class="section-heading">Global options</h2>
              <p>
                Add a global option by clicking the 'plus' box at the top of the template logic page, you can then define the type, name, and default
                value of the global option. A global options type can be either a <code>dropdown menu</code>, <code>text</code>,
                <code>multiline text</code>, <code>media replacement</code> or <code>comment</code>, on the online editor global options are presented
                in the exact same way as mogrt options of the same type, however they appear in their own section.
              </p>
              <p>
                Each global option can also be set to show or hide based on the values of other global options, allowing you to only show options when
                other global options have specific values set, and hide options that are not relevant, to define show/hide conditions for global
                options, click the lines icon to the right of a global option.
              </p>
            </section>
            <!--//section-->

            <section class="docs-section" id="predefined-sequences">
              <h2 class="section-heading">Predefined sequences</h2>
              <p>
                If you would not like your editors to build their own sequence of mogrts, but rather have a set sequence that is altered based on the
                global options and your specific conditions, then you can enable a predefined sequence. To do this, select the option "Editors use a
                predefined sequence," you can now click the 'plus' box below this to add mogrts to this predefined sequence, you can then set
                show/hide conditions for each mogrt in the sequence, and link mogrt options to global options by clicking the lines icon on the bottom
                right of the each mogrt.
              </p>
              <p>
                Note that this can be used to define different dimensions of the final video, you can simply add all your mogrts of varying
                dimensions, then define show/hide conditions so that only one dimension is shown when a specfic global dropdown value is selected.
              </p>
            </section>
            <!--//section-->

            <section class="docs-section" id="mogrt-show-hide">
              <h2 class="section-heading">Mogrt show/hide conditions</h2>
              <p>
                Show/hide conditions can be defined on a mogrt by clicking the lines icon on the bottom right of a mogrt at the bottom of the template
                logic page, at the bottom of the resulting dialog you will see a teal 'plus' box, click this to add a condition to show or hide this
                mogrt.
              </p>
              <figure class="figure docs-figure py-3">
                <img
                  @click="opensilentbox('assets/docs-img/show-hide.jpg')"
                  class="figure-img img-fluid"
                  src="assets/docs-img/show-hide.jpg"
                  alt
                  style="width: 700px; cursor: pointer"
                />
              </figure>
              <p>
                You are able to define separate condition groups with multiple conditions in each, as well as define the logic operator (and/or)
                within, and in-between each condition group. If show/hide conditions are added to a mogrt when a predefined sequence is not being
                used, then when a mogrt is hidden, it will be removed from both the mogrt browser panel on the online editor, as well as any time the
                mogrt has been used in the timeline, effectively making a mogrt not usable at all. If applied to a mogrt that's in a predefined
                sequence, this specific instance of the mogrt in the predefined sequence will be kept/removed in the timeline.
              </p>
            </section>
            <!--//section-->

            <section class="docs-section" id="option-linking">
              <h2 class="section-heading">Linking mogrt options to global options</h2>
              <p>
                Each individual option within a mogrt can be driven by a global option, to do this, click the lines icon on the bottom right of a
                mogrt at the bottom of the template logic page, then in the resulting dialog, find the option you would like to link to a global
                option, and click the 'link' icon on the right of the option, you can then select the global option you would like to link this option
                to. When a mogrt option is linked to a global option, the link button will appear teal and show the name of the global option it is
                linked to. Any mogrt options that are linked to global options will no longer appear on the online editor, as it is now controlled by
                the global option.
              </p>
            </section>
            <!--//section-->

            <section class="docs-section" id="dropdown-mapping">
              <h2 class="section-heading">Dropdown mapping</h2>
              <p>
                If a mogrt option is linked to a global option that is a dropdown menu, then you will need to define how each selection in the global
                dropdown affects this mogrt option, this is referred to as dropdown mapping, ie. you are mapping each dropdown selection to a
                resulting value for this mogrt option.
              </p>
              <figure class="figure docs-figure py-3">
                <img
                  @click="opensilentbox('assets/docs-img/dropdown-mapping.jpg')"
                  class="figure-img img-fluid"
                  src="assets/docs-img/dropdown-mapping.jpg"
                  alt
                  style="width: 700px; cursor: pointer"
                />
              </figure>
              <p>
                In the above example, a mogrt option that has the option type
                <code>color</code> has been linked to a global option that is a <code>dropdown</code>, therefor we must define which color this mogrt
                option will be set to for each dropdown selection.
              </p>
              <p>
                If the mogrt option is a text option, then you will also see a button above the mapping "Populate all with dropdown option names"
                clicking this will automatically populate all dropdown mappings with the names of the dropdown options, ie. if the dropdown option is
                "Red" then the text option in the mogrt will be set to "Red" for this dropdown selection.
              </p>
            </section>
            <!--//section-->

            <section class="docs-section" id="mogrt-expressions">
              <h2 class="section-heading">Mogrt option expressions</h2>
              <p>
                If a simple link to a global options value is not enough, you are able to set up a more advanced connection to global options by using
                an expression, meaning you can drive a mogrt options value with very specific logic as well as augment and combine values of multiple
                global options using a JavaScript expression. The result that the expression evaluates to, is what this mogrt options value will be
                set to.
              </p>
              <figure class="figure docs-figure py-3">
                <img
                  @click="opensilentbox('assets/docs-img/expressions.jpg')"
                  class="figure-img img-fluid"
                  src="assets/docs-img/expressions.jpg"
                  alt
                  style="width: 700px; cursor: pointer"
                />
              </figure>
              <p>
                Add an expression to a mogrt option by clicking the curly braces icon to the right of the mogrt option, these expressions use the same
                JavaScript language as expressions in After Effects. A key aspect of these expressions is that you can plug the value of any global
                options into your expression, do this by right clicking in the expression editor and select a global option, once your expression is
                ready, click "Evaluate expression" below the expression editor to view what the resulting value of your expression is.
              </p>
              <p>
                A basic example would be if you have a global drop-down, lets call it <code>Global dropdown</code>, and you want a mogrt text option
                to be set to the value of <code>Global dropdown</code>, ie. whatever drop-down option is selected, the text will be set to that
                drop-down option text, the expression to do this would be this:
              </p>
              <code style="display: inline-block; margin-bottom: 30px; margin-left: 20px"
                ><span style="display: block">globalOption("Global dropdown").value;</span> </code
              ><i class="fas fa-clone copy" text='globalOption("Global dropdown").value;' @click="copyText($event)"></i>
              <p>
                Here I've simply right clicked in the expression editor and inserted the value for <code>Global dropdown</code>. But what if you
                wanted to have a drop-down value at the bottom of the list in <code>Global dropdown</code> called <code>Custom</code> and you want to
                make it so that if the user selects <code>Custom</code> then they'll be able to set the text to their own custom text through a text
                input, what we could do is make another global option, make it a text field, lets name it <code>Custom text</code> then use an if-then
                in your expression to check if the drop-down is set to <code>Custom</code>, if so, use the value of <code>Custom text</code> otherwise
                use the value from the drop-down, the expression would look like this:
              </p>
              <code style="display: inline-block; margin-bottom: 30px; margin-left: 20px"
                ><span style="display: block">if (globalOption("Global dropdown").value === "Custom") {</span>
                <span style="display: block">&nbsp;&nbsp;globalOption("Custom text").value;</span>
                <span style="display: block">} else {</span>
                <span style="display: block">&nbsp;&nbsp;globalOption("Global dropdown").value;</span>
                <span style="display: block">}</span> </code
              ><i
                class="fas fa-clone copy"
                text='if (globalOption("Global dropdown").value === "Custom") {
  globalOption("Custom text").value;
} else {
  globalOption("Global dropdown").value;
}'
                @click="copyText($event)"
              ></i>
              <p>
                To take this a step further, you could also apply show/hide logic to the <code>Custom text</code> input so that it only shows if the
                drop-down is set to <code>Custom</code>. Your user is now able to pick a value from the drop-down, but also select custom if they
                would like to enter their own custom text value. This is quite a basic example, but the idea is that it should allow you to build much
                more advanced logic into your templates options and even take values from multiple different global options to do if-then checks and
                augment their values in useful ways.
              </p>
            </section>
            <!--//section-->
          </article>

          <article class="docs-article">
            <header class="docs-header">
              <h1 class="docs-heading" id="rendering-edits">Rendering edits</h1>
              <section class="docs-intro docs-section">
                <p>
                  Videos are rendered on the pc running the desktop app, no After Effects project files or footage files ever leave the pc running the
                  desktop app. When a user is done editing online, they can indicate that their edit is ready to render by clicking the render button,
                  the desktop app will receive this request and render the edit based on the render settings for that template, as defined in the app.
                </p>
                <p>
                  Access the render settings for a template selecting the "Render settings" tab in the app, here you can select which format you would
                  like the app to output. All videos are first rendered into the MoDeck temp folder, by default this is located in your MoDeck sync
                  folder at
                  <code>MoDeck Sync\modk-data\Temp</code>, after rendering is complete, the finished video file is moved to the final destination
                  folder, defined by the render setting "All completed renders moved here"
                </p>
                <p>
                  If the formats PNG or JPG are selected, then a different still image will be rendered for each mogrt in the sequence of the edit,
                  the time used for these still frames is defined in the mogrt using markers on the mogrts main comp, markers with their comments set
                  to <code>modk-render</code>. If you would like to use the same point in time as a render frame and a preview frame, you can add both
                  modk-preview and modk-render to the same maker comment, but separate them with a comma, eg.
                  <code>modk-render, modk-preview-Name</code>
                </p>
                <p>
                  If the "Encoder preset" output is selected, then you are able to select one or more Encoder preset files (.epr files) to use for the
                  rendering, one video will be rendered for each .epr file you've added. Now when a render is requested, the MoDeck app will
                  communicate directly with Encoder to render the video using your preset, if Encoder is not currently running, it will automatically
                  launch Encoder.
                </p>
                <p>
                  The render settings is also where you define file naming conventions for the rendered videos, there is a different convention used
                  for single renders and multiple video renders/data renders. You are able to select dynamic data to plug into the file name by
                  hovering the "Add dynamic data" button, included in the dynamic data are your
                  <a href="#global-options">global options</a>, meaning you are able to use the value that was set for any global option in the
                  filename. You are also able to include slashes in your naming convention in order to have it place the rendered items into sub
                  folders, for example you could use this to have rendered file organized into dated folders after upload.
                </p>
                <p>
                  If you would like for more advanced automated adjustments to be made to the project files that MoDeck outputs, you can add a jsx
                  script file into your template folder, this extendscript script will be executed at the end of the After Effects assembly script,
                  right before it saves out the aep to be rendered, this script will be run whenever a render is requested and an aep is assembled.
                  This script has access to several variables pertaining to the edit that is being assembled, including<code>config.editName</code>
                  (Name of the edit) and <code>config.moseq</code> (Array of all mogrts in the sequence, each containing all that mogrts data
                  including its options)
                </p>
              </section>
            </header>
            <!--//docs-intro-->

            <section class="docs-section" id="rendering-issues">
              <h2 class="section-heading">Dealing with render issues</h2>
              <p>
                If you are running into errors when rendering, the best thing to try is to clear the temporary files, this is done through the app
                settings. It is also important to ensure that you have enough space on your drive, a full drive will cause renders to fail.
              </p>
              <p>
                If the videos are rendering without error, but the video content itself is broken is some way, such as layout issues, timing issues or
                the video is just black, what you can do is go into the MoDeck temporary files at
                <code>MoDeck Sync\modk-data\Temp</code> to find the aep that was generated for the render, then open this aep, the comp at the root of
                the project is the comp that would have been rendered, here you can navigate the project to see if you can spot where things went
                wrong, and see if there are corrections you can make to your mogrts to resolve them.
              </p>
              <p>
                It is also possible that a background instance of After Effects has gotten stuck and was not closed correctly which could affect
                rendering, first close Media Encoder, then close the background instance of AE by clicking the AE icon on the app, then open you task
                manager and look for any instance of After Effects and close them, now start up the background AE again.
              </p>
              <p>
                If you are seeing issues in only the preview images that are being shown on the editor, but not in the final rendered videos, then you
                are able to debug issues with previews by finding the aep that was generated for the preview, the aep for the latest generated preview
                is located in the MoDeck temp folder at
                <code>MoDeck Sync\modk-data\Temp\preview-frames\latest_preview.aep</code>
                you can open this aep and see if you can spot what's causing the issue.
              </p>
            </section>
            <!--//section-->

            <section class="docs-section" id="multimachine">
              <h2 class="section-heading">Multi machine rendering</h2>
              <p>
                If you would like to share the render load across multiple machines, you are able to simply install and run the app on multiple
                machines, all signed into the same MoDeck account, these separate instances of the app will now share the rendering requests, render
                requests made from the online editor will be delegated by the MoDeck server to whichever running app currently has the least amount of
                concurrent render requests. When running multiple instances of the app it is important to make sure all the machines are sharing the
                same mogrt files, there is also a file in your MoDeck template folders that was generated by the MoDeck app called ".modeck" (This
                file may be hidden on MacOS) in order to ensure that each template on each machine is referencing the same online template, it is
                important that your templates each have this same ".modeck" file alongside their mogrts, this file simply contains the unique ID for
                the template, when multiple machines has template folders with the same ".modeck" file, they will be referencing the same online
                template, so make sure you copy this file to other machines along with the mogrts.
              </p>
              <p>
                It is up to you to ensure that each machine has the same mogrt files, in template folders that have the same name, and the same
                ".modeck" file in each of those template folders. Note that this will not speed up individual renders, but rather distribute each
                edits to render simultaneously on different machines, good for when you will need to render many different edits all at once.
              </p>
            </section>
            <!--//section-->
          </article>

          <article class="docs-article">
            <header class="docs-header">
              <h1 class="docs-heading" id="mogrt-sequences">Mogrt sequences</h1>
              <section class="docs-intro docs-section">
                <p>
                  With the online editor, editors have the ability to arrange the mogrts in a template into a sequence by dragging them onto the
                  timeline in the editor and dragging them to rearrange them. As an After Effects user creating a template for MoDeck it is helpful to
                  understand how sequences will be handled in After Effects when an edit gets rendered.
                </p>
                <p>
                  Each new mogrt comp in the sequence comes in on top of the previous, if modk-durationEditable is used in the mogrt, then the
                  duration of the comp will be set to the duration that was defined on the online editor. Be aware that if any of your mogrts are
                  introduced by animating in on a transparent background, then the previous mogrt will end, and the new mogrt will animate in on top
                  of nothing, ie it will be blank under that mogrt, to correct this, use the
                  <a href="#modk-commands">modk-overlap</a> command to make it animate in sooner to animate on top of the previous.
                </p>
                <figure class="figure docs-figure py-3">
                  <img
                    @click="opensilentbox('assets/docs-img/overlap.jpg')"
                    class="figure-img img-fluid"
                    src="assets/docs-img/overlap.jpg"
                    alt
                    style="width: 800px; cursor: pointer"
                  />
                </figure>
                <p>
                  The comp that this sequence is in will be named
                  <code>modk-sequence</code> meaning you can use expressions in your mogrt comps that reference this comp in order to give you access
                  to the sequence of mogrts from within one of your mogrt comps, for example this can allow you to have a
                  <a href="#base-mogrts">base mogrt</a> which uses an expression on a layer to make it respond to whichever mogrt is currently playing
                  in the sequence.
                </p>
                <p>
                  Here is an example expression which fades the audio of a layer in the base mogrt, depending on if the mogrt called "Footage" is
                  currently playing in the sequence, add the effect "Stereo Mixer" then add this expression to the left and right levels:
                </p>
                <code style="display: inline-block; margin-bottom: 30px; margin-top: 20px"
                  ><span style="display: block">var mogrtName = "Footage";</span>
                  <span style="display: block">var maxVal = 100;</span>
                  <span style="display: block">var minVal = 50;</span>
                  <span style="display: block">var fadeTime = .5;</span>
                  <span style="display: block">try {</span>
                  <span style="display: block">&nbsp;&nbsp;var seqComp = comp("modk-sequence");</span>
                  <span style="display: block">&nbsp;&nbsp;var mogrtPlaying;</span>
                  <span style="display: block">&nbsp;&nbsp;for (var i = 1; i &lt; seqComp.numLayers; i++) {</span>
                  <span style="display: block"
                    >&nbsp;&nbsp;&nbsp;&nbsp;if (seqComp.layer(i).name === mogrtName && time >= seqComp.layer(i).inPoint && time &lt;
                    seqComp.layer(i).outPoint) {</span
                  >
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;mogrtPlaying = seqComp.layer(i);</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;}</span>
                  <span style="display: block">&nbsp;&nbsp;}</span>
                  <span style="display: block">&nbsp;&nbsp;if (mogrtPlaying) {</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;Math.max(</span>
                  <span style="display: block"
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;linear(time, mogrtPlaying.inPoint, mogrtPlaying.inPoint + fadeTime, maxVal, minVal),</span
                  >
                  <span style="display: block"
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;linear(time, mogrtPlaying.outPoint - fadeTime, mogrtPlaying.outPoint, minVal, maxVal));</span
                  >
                  <span style="display: block">&nbsp;&nbsp;} else {</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;maxVal;</span>
                  <span style="display: block">&nbsp;&nbsp;}</span>
                  <span style="display: block">} catch (err) {</span>
                  <span style="display: block">&nbsp;&nbsp;value;</span>
                  <span style="display: block">}</span> </code
                ><i
                  class="fas fa-clone copy"
                  text='var mogrtName = "Footage";
var maxVal = 100;
var minVal = 50;
var fadeTime = .5;
try {
  var seqComp = comp("modk-sequence");
  var mogrtPlaying;
  for (var i = 1; i < seqComp.numLayers; i++) {
    if (seqComp.layer(i).name === mogrtName && time >= seqComp.layer(i).inPoint && time < seqComp.layer(i).outPoint) {
      mogrtPlaying = seqComp.layer(i);
    }
  }
  if (mogrtPlaying) {
    Math.max(
      linear(time, mogrtPlaying.inPoint, mogrtPlaying.inPoint + fadeTime, maxVal, minVal),
      linear(time, mogrtPlaying.outPoint - fadeTime, mogrtPlaying.outPoint, minVal, maxVal));
  } else {
    maxVal;
  }
} catch (err) {
  value;
}'
                  @click="copyText($event)"
                ></i>
              </section>
            </header>
            <!--//docs-intro-->

            <section class="docs-section" id="transitioning">
              <h2 class="section-heading">Transitioning between mogrts</h2>
              <p>
                The easiest way to add transitions between each mogrt in the sequence is to simply add your transition animation at the beginning of
                each mogrt and have elements animate in on transparency, since each new mogrt will be added on top of the last, this will animate in
                on top of the previous, if doing it this way, make sure you use the
                <a href="#modk-commands">modk-overlap</a> command so that the intro animation happens on top of the previous mogrt in the sequence.
                But what if you have a mogrt that has editable duration and you want elements in it to animate away at the end of that mogrt? For this
                you can either use protected regions in your mogrt, if using protected regions make sure that your modk-durationEditable command has
                "stretch" added to it, or you can set up the outro animations in the mogrt to have their
                <a href="#keyframe-expressions">keyframes linked to the duration of the comp</a>, this way that animation will always be shifted to
                the end of the comp, no matter what it has been changed to through the online editor.
              </p>
              <p>
                And what about a scenario where you do not want a transition to show at the beginning of the mogrt if it is the first mogrt of the
                sequence? This can be done using the
                <a href="#modk-commands">modk-index command</a>, using this command you can determine if a mogrt has an index of 0, meaning it is the
                first in the sequence, use an expression on the opacity of the transition layers to hide them if the index is 0.
              </p>
              <p>
                A combination of
                <a href="#modk-commands">modk-index, modk-count</a> and expressions driven by the mogrts comp duration can be used in any number of
                ways to make the mogrts in the sequence responsive to the order and arrangement they appear in the finished video, allowing you to
                make templates capable of creating videos suitable to a very broad range of applications.
              </p>
            </section>
            <!--//section-->
          </article>

          <article class="docs-article">
            <header class="docs-header">
              <h1 class="docs-heading" id="data-driven">Data driven videos (versioning)</h1>
              <section class="docs-intro docs-section">
                <p>
                  With the online editor, you have the ability to link editable properties of your mogrts to data, to create many videos at once or
                  drive properties with dynamic content from online APIs. You can upload a csv file, creating a spreadsheet directly in the editor, or
                  linking properties to JSON data from a URL. This is all done by clicking settings in the editor, where you will see 3 buttons:
                  "Upload csv file," "New blank spreadsheet" and "Connect JSON data."
                </p>
              </section>
            </header>

            <section class="docs-section" id="data-spreadsheets">
              <h2 class="section-heading">Using spreadsheets</h2>
              <p>
                once you've added a spreadsheet you'll be able to edit its data by typing into individual cells and adding rows and columns. Once a
                spreadsheet has been added you will see a button appear alongside every option for each mogrt, clicking this link-to-data button will
                allow you to link that option to a selected column from the csv file, when this edit is rendered it will now render a new video for
                every row of the spreadsheet, inputting the data from each row into the appropriate options that have been linked. All the rendered
                video files for this set will be uploaded as a zip file and the editor will be able to download this zip file in the same way they
                would download a single video file.
              </p>
              <figure class="figure docs-figure py-3">
                <img
                  @click="opensilentbox('assets/docs-img/versioning.jpg')"
                  class="figure-img img-fluid"
                  src="assets/docs-img/versioning.jpg"
                  alt
                  style="width: 1100px; cursor: pointer"
                />
              </figure>
              <p>
                Spreadsheet columns can be linked to file inputs, meaning you're able to render multiple videos each using different image or video
                files, you can simply enter the filenames of files from your media library into spreadsheet cells, then link that column to a file
                input option of a mogrt, an easier way to do this is to right click on a cell and select "Add a media file" this lets you select a
                file from your media library to place in that cell. You are also able to use any files that exist in the folder
                <code>MoDeck Sync\modk-data\User media</code> in the sync folder of machine running the desktop app, just add it's filename into a
                spreadsheet cell, when an edit is being assembled by the app, it will always look for media files in that User media folder. You can
                also add media in the form of a URL that directs to an image or video, the desktop app will detect that it is a URL and download the
                required media to place it into your template.
              </p>
              <p>
                When creating a template in AE you can also define default spreadsheet data that will be added to all new edits made with that
                template, to do this just add the csv data to the Essential Graphics in any one of the mogrts of that template, to add csv data to
                Essential Graphics, import your csv file into your AE project, place that csv file into one of your comps, twirl open that csv layer
                and you will see a "data" property group, drag this "data" into your Essential Graphics panel and it will be added as a spreadsheet
                option.
              </p>
            </section>
            <!--//section-->

            <section class="docs-section" id="data-json">
              <h2 class="section-heading">Using JSON data</h2>
              <p>
                Connecting properties to JSON data works very similarly to connecting to spreadsheet data, but with JSON data the data is pulled from
                an online URL, once you've clicked "Connect JSON data" in the editor settings, you will see an input for entering a URL, enter a URL
                that leads to your JSON file or API that returns JSON data (The URL must be an HTTPS URL, as opposed to HTTP). Much like with
                spreadsheet linking, after entering this URL you will see a button next to all of your editable properties, clicking this button will
                present you with the JSON data that was pulled from the URL, you can now navigate this data and click on one of the JSON values to
                link that value to this mogrt property, note that this data will be fetched by the desktop app every time this edit is rendered, so
                the data it renders with will always be current. Just like with spreadsheets you can link media inputs to values from JSON data that
                are URLs leading to media files, the desktop app will detect that it is a URL and download the media from that URL to use as the
                replacement media.
              </p>
              <p>
                You can also use JSON data to render multiple versions of a video, this is done by clicking "Select array for multiple video
                rendering" under the URL input, this will show the JSON data from the URL, where you can select an array element from the JSON data
                which you would like to use as the array to iterate over to generate videos, a video will be rendered for every element of this array,
                any properties linked to JSON data that is part of this array will get its data from each new array element for each video.
              </p>
              <p>
                When using JSON data, you also have the ability to schedule renders to continuously render at set times and days, to do this, select
                the "Schedule render" switch below the JSON URLs in the edits settings, you can then select the interval to render, every time a
                render is initiated, new data is fetched from your JSON URL.
              </p>
              <p>
                Once JSON data has been added to an edit, you will also have the ability to right click on just about any text input and insert text
                from JSON data, meaning you can combine a value pulled from a JSON URL into other text, this can even be done with the URL inputs for
                the JSON data itself, meaning you can add a new URL, then insert text from another URL into that URL, essentially chaining JSON
                requests. This can also be done with media inputs; once you've clicked on "Select media" on a media input, you can right click in the
                text input at the top of the media selection dialog to dynamically insert values from data into the filename or URL that this media
                item will use.
              </p>
            </section>
            <!--//section-->
          </article>

          <article class="docs-article">
            <header class="docs-header">
              <h1 class="docs-heading" id="customize">Editor customization</h1>
              <section class="docs-intro docs-section">
                <p>
                  Enterprise users have the ability to customize the color scheme and logo of the online editor as it appears to their invited
                  editors, as well as create 'Magic links,' which can be given to anyone to allow them to edit your templates without needing an
                  account, magic links can also be embedded onto your sites.
                </p>
              </section>
            </header>
            <!--//docs-intro-->

            <section class="docs-section" id="white-labeling">
              <h2 class="section-heading">Color schemes and logo</h2>
              <p>
                To customize the color scheme and logo of the editor, open the online editor and access the settings via the profile icon and the top
                right, on the left side of the settings page select "Customize," here you can upload a logo and adjust colors.
              </p>
              <p>
                If an editor has been invited to templates from multiple users, then their edit selection page will use the default color scheme and
                logo, but the relevant customizations will be applied in the mogrt editor page. When an editor accesses your template via a Magic link
                it will always apply your color scheme and logo to the edit selection page and mogrt editor page.
              </p>
              <p>
                When adding a logo, keep in mind that this logo will be shown at a small size on the top left of the editor, so avoid using logos with
                intricate details, it is generally preferable to use a solid color, iconic logo that is a png with transparency.
              </p>
              <p>
                When setting up your color scheme, avoid setting the "primary" color to something that is overly bright or highly saturated as it will
                overpower the interface and become distracting.
              </p>

              <figure class="figure docs-figure py-3">
                <img
                  @click="opensilentbox('assets/docs-img/color_scheme.jpg')"
                  class="figure-img img-fluid"
                  src="assets/docs-img/color_scheme.jpg"
                  alt
                  style="width: 640px; cursor: pointer"
                />
              </figure>
            </section>
            <!--//section-->

            <section class="docs-section" id="magic-links">
              <h2 class="section-heading">Magic links and embedding the editor on your site</h2>
              <p>
                "Magic links" are links that can be created with the desktop app and provided to others to allow them to easily edit your templates
                without them needing a MoDeck account, anyone who has a link will be able to make edits and request renders for that template.
              </p>
              <p>
                To create a magic link, open the desktop app, navigate to the template which you would like to create a link for, then select user
                management, here you will see a magic links section where you can enter a name to be associated with this link and an email where
                render notifications will be sent to, once created you can then copy the link to send to anyone you would like to provide access to
                this template.
              </p>
              <p>
                All notifications for renders made through a magic link will be sent to the email address associated with that link, this is the email
                entered when you are creating the magic link on the desktop app, you can also leave the email field blank to have it set to your own
                email address, meaning you will receive all notifications of renders for edits created through this link.
              </p>
              <p>
                You can also associate multiple emails with one link, do this by clicking "Add email" on an existing magic link, if more than one
                email is associated with a link then a password will be required to use the link, the password they enter will determine which email
                their notifications go to, get the password for each email by clicking "Copy pass" next to the email under Magic links on the desktop
                app. You can also include the password in the link itself, that way when the user opens the link they will not need to enter the
                password, do this by adding it to the end of the magic link URL as a query string called "p" basically just add "&p=PASSWORD" onto the
                end of the URL (replacing PASSWORD with the password, eg.
                https://app.modeck.io/?m=0123456789&d=0123456789<strong>&p=tgarxiwb</strong>)
              </p>
              <p>
                These magic links can also be embedded onto your own site using an iframe, simply click "Copy embed code" and you will be able to
                paste this html into your own site, it may be required that you adjust the css accordingly so that the sizing works correctly on your
                site.
              </p>
            </section>
            <!--//section-->

            <section class="docs-section" id="notification-emails">
              <h2 class="section-heading">Customizing notification emails</h2>
              <p>
                Notification emails for new invitations and new renders can be entirely customized, this is only possible if you have enabled "Use own
                email for notification emails" in the app settings. This is an enterprise feature.
              </p>
              <p>
                Emailer customization is done per template, through the User management section of the app. You can either do a simple customization
                where you only change the button that appears in the emails (Customize button text and button link to point your users somewhere other
                than the MoDeck editor) or you can replace the entire HTML of the emailers that are sent.
              </p>
              <p>
                Dynamic text is added into these buttons or email html templates using double curly braces (eg.
                <span v-html="'{{editName}}'"></span>) below are all the possible dynamic values that can be used in custom templates, note that some
                of these are not used in some of the mails (eg. editName would not be present in new invite emails).
              </p>
              <ul>
                <li style="padding: 0" v-html="'{{deckName}}'"></li>
                <li style="padding: 0" v-html="'{{editName}}'"></li>
                <li style="padding: 0" v-html="'{{deckID}}'"></li>
                <li style="padding: 0" v-html="'{{editID}}'"></li>
                <li style="padding: 0" v-html="'{{magicLinkHash}}'"></li>
                <li style="padding: 0" v-html="'{{hashPass}}'"></li>
              </ul>
            </section>
            <!--//section-->
          </article>

          <article class="docs-article">
            <header class="docs-header">
              <h1 class="docs-heading" id="modeckapi">The MoDeck API</h1>
              <section class="docs-intro docs-section">
                <p>
                  MoDeck offers an easy to use API that allows you to essentially skip using the online editor and programmatically communicate
                  directly with the desktop app, allowing you to build your own online interfaces or automated process for triggering renders of your
                  templates, fetching previews, fetching all the data about your mogrts etc. To begin using the API, you will first need to get your
                  API key, this can be found at the bottom of the
                  <a href="https://app.modeck.io/settings" target="_blank">account settings on the online app.</a>
                  This API key must be added to all requests to authenticate them with your account, the API key can alternatively be placed in the
                  header of the request, name the header "authorization" with its value set to your API key.
                </p>
                <br />

                <h4 style="border-top: 1px solid #e7e9ed; padding-top: 30px">
                  <code>https://api.modeck.io/listdecks</code
                  ><i class="fas fa-clone copy" text="https://api.modeck.io/listdecks" @click="copyText($event)"></i>
                </h4>
                <code style="display: inline-block; margin-bottom: 30px; margin-top: 20px"
                  ><span style="display: block">{</span>
                  <span style="display: block">&nbsp;&nbsp;apiKey: "YOUR_API_KEY"</span>
                  <span style="display: block">}</span> </code
                ><i
                  class="fas fa-clone copy"
                  text='{
  apiKey: "YOUR_API_KEY"
}'
                  @click="copyText($event)"
                ></i>
                <p>
                  <strong>This route accepts POST and GET requests.</strong>
                  This returns an array of all of your templates, as well as all of the mogrt data for each template containing all available options
                  for the mogrts.
                </p>
                <p>
                  Each mogrt option has a <code>type</code> key which denotes the option type with the value being a number, below is what each number
                  represents:
                </p>
                <ul>
                  <li style="padding: 0">1 = Checkbox</li>
                  <li style="padding: 0">2 = Number</li>
                  <li style="padding: 0">3 = Angle</li>
                  <li style="padding: 0">4 = Color</li>
                  <li style="padding: 0">5 = 2D Number</li>
                  <li style="padding: 0">6 = Text</li>
                  <li style="padding: 0">7 = Multiline Text</li>
                  <li style="padding: 0">8 = Comment</li>
                  <li style="padding: 0">9 = 2D Scale</li>
                  <li style="padding: 0">10 = Group</li>
                  <li style="padding: 0">11 = Spreadsheet</li>
                  <li style="padding: 0">13 = Dropdown menu</li>
                  <li style="padding: 0">14 = Media replacement</li>
                </ul>

                <br />

                <h4 style="border-top: 1px solid #e7e9ed; padding-top: 30px">
                  <code>https://api.modeck.io/render</code
                  ><i class="fas fa-clone copy" text="https://api.modeck.io/render" @click="copyText($event)"></i>
                </h4>
                <code style="display: inline-block; margin-bottom: 30px; margin-top: 20px"
                  ><span style="display: block">{</span>
                  <span style="display: block">&nbsp;&nbsp;apiKey: "YOUR_API_KEY",</span>
                  <span style="display: block">&nbsp;&nbsp;deck: "NAME_OF_TEMPLATE",</span>
                  <span style="display: block">&nbsp;&nbsp;name: "NAME_OF_THIS_EDIT",</span>
                  <span style="display: block">&nbsp;&nbsp;editId: "OPTIONAL_EDIT_ID",</span>
                  <span style="display: block">&nbsp;&nbsp;notificationEmail: "email@email.com",</span>
                  <span style="display: block">&nbsp;&nbsp;mogrtSeq: [{</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;name: "MOGRT_NAME",</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;options: [{</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;name: "OPTION_NAME",</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;value: "OPTION_VALUE"</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;}],</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;duration: 10</span>
                  <span style="display: block">&nbsp;&nbsp;}],</span>
                  <span style="display: block">&nbsp;&nbsp;globalOptions: [{</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;name: "GLOBAL_OPTION_NAME",</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;value: "GLOBAL_OPTION_VALUE"</span>
                  <span style="display: block">&nbsp;&nbsp;}]</span>
                  <span style="display: block">}</span> </code
                ><i
                  class="fas fa-clone copy"
                  text='{
  apiKey: "YOUR_API_KEY",
  deck: "NAME_OF_TEMPLATE",
  name: "NAME_OF_THIS_EDIT",
  editId: "OPTIONAL_EDIT_ID",
  notificationEmail: "email@email.com",
  waitForRender: false,
  mogrtSeq: [{
    name: "MOGRT_NAME",
    options: [{
      name: "OPTION_NAME",
      value: "OPTION_VALUE"
    }],
    duration: 10
  }],
  globalOptions: [{
    name: "GLOBAL_OPTION_NAME",
    value: "GLOBAL_OPTION_VALUE"
  }]
}'
                  @click="copyText($event)"
                ></i>
                <p>
                  <strong>This route accepts POST requests.</strong>
                  If <code>editId</code> is provided then this will update the existing edit with the supplied data and render it, otherwise a new
                  edit will be created with the supplied data and rendered.
                </p>
                <p>
                  Multiple mogrt objects can be added to the
                  <code>mogrtSeq</code> array to form a <a href="#mogrt-sequences">sequence</a>, much like the sequence you would create on the
                  timeline on the online editor. You do not need to add all available options into the options array for each mogrt, you can simply
                  add the option objects that you wish to edit, any options not provided in your request will stay at their default values.
                </p>
                <p>
                  It is recommend that you supply <code>editId</code> if you are going to be rendering a large volume of videos through this API call,
                  as this will prevent the template from getting flooded with new edits created every time a render is initiated through the API.
                </p>
                <p>
                  This request will respond with an editId, this is the ID of the edit that will be rendered, post another request to /renderstatus
                  using this editId to get the status of this render.
                </p>
                <br />

                <h4 style="border-top: 1px solid #e7e9ed; padding-top: 30px">
                  <code>https://api.modeck.io/renderstatus</code
                  ><i class="fas fa-clone copy" text="https://api.modeck.io/renderstatus" @click="copyText($event)"></i>
                </h4>
                <code style="display: inline-block; margin-bottom: 30px; margin-top: 20px"
                  ><span style="display: block">{</span>
                  <span style="display: block">&nbsp;&nbsp;apiKey: "YOUR_API_KEY",</span>
                  <span style="display: block">&nbsp;&nbsp;editId: "EDIT_ID"</span>
                  <span style="display: block">}</span> </code
                ><i
                  class="fas fa-clone copy"
                  text='{
  apiKey: "YOUR_API_KEY",
  editId: "EDIT_ID"
}'
                  @click="copyText($event)"
                ></i>
                <p>
                  <strong>This route accepts POST and GET requests.</strong>
                  This will fetch the render status of an edit, this response will contain an array of available renders with a download link for
                  each, the download links will expire 2 hours after this response was received.
                </p>
                <p>
                  Note: You are also able to set up webhooks for render status updates, ie. You can have MoDeck send a POST request to your given
                  endpoint whenever render status updates, to do this enter a URL at the bottom of the Render settings in the desktop app, whenever
                  the status of a render updates, a POST request will be sent to the supplied URL containing the details of the status update, if the
                  render has completed, this data will contain links to the rendered video files for the associated edit.
                </p>
                <br />

                <h4 style="border-top: 1px solid #e7e9ed; padding-top: 30px">
                  <code>https://api.modeck.io/listrenders</code
                  ><i class="fas fa-clone copy" text="https://api.modeck.io/listrenders" @click="copyText($event)"></i>
                </h4>
                <code style="display: inline-block; margin-bottom: 30px; margin-top: 20px"
                  ><span style="display: block">{</span>
                  <span style="display: block">&nbsp;&nbsp;apiKey: "YOUR_API_KEY",</span>
                  <span style="display: block">&nbsp;&nbsp;deck: "NAME_OF_TEMPLATE",</span>
                  <span style="display: block">&nbsp;&nbsp;editName: "OPTIONAL_NAME_OF_EDIT",</span>
                  <span style="display: block">&nbsp;&nbsp;editId: "OPTIONAL_ID_OF_EDIT"</span>
                  <span style="display: block">}</span> </code
                ><i
                  class="fas fa-clone copy"
                  text='{
  apiKey: "YOUR_API_KEY",
  deck: "NAME_OF_TEMPLATE",
  editName: "OPTIONAL_NAME_OF_EDIT",
  editId: "OPTIONAL_ID_OF_EDIT"
}'
                  @click="copyText($event)"
                ></i>
                <p>
                  <strong>This route accepts POST and GET requests.</strong>
                  This will fetch a list of URLS for all completed renders in a template in chronological order. If an editName is supplied then this
                  list will be filtered by only renders from edits with the given name, same applies to editId; if supplied only renders for the edit
                  with the given ID will be listed.
                </p>
                <br />

                <h4 style="border-top: 1px solid #e7e9ed; padding-top: 30px">
                  <code>https://api.modeck.io/getdeck</code
                  ><i class="fas fa-clone copy" text="https://api.modeck.io/getdeck" @click="copyText($event)"></i>
                </h4>
                <code style="display: inline-block; margin-bottom: 30px; margin-top: 20px"
                  ><span style="display: block">{</span>
                  <span style="display: block">&nbsp;&nbsp;apiKey: "YOUR_API_KEY",</span>
                  <span style="display: block">&nbsp;&nbsp;deck: "NAME_OF_TEMPLATE"</span>
                  <span style="display: block">}</span> </code
                ><i
                  class="fas fa-clone copy"
                  text='{
  apiKey: "YOUR_API_KEY",
  deck: "NAME_OF_TEMPLATE"
}'
                  @click="copyText($event)"
                ></i>
                <p>
                  <strong>This route accepts POST and GET requests.</strong>
                  This will fetch a single template, this template object will contain all the mogrts for that template and their available options.
                </p>
                <br />

                <h4 style="border-top: 1px solid #e7e9ed; padding-top: 30px">
                  <code>https://api.modeck.io/getmogrt</code
                  ><i class="fas fa-clone copy" text="https://api.modeck.io/getmogrt" @click="copyText($event)"></i>
                </h4>
                <code style="display: inline-block; margin-bottom: 30px; margin-top: 20px"
                  ><span style="display: block">{</span>
                  <span style="display: block">&nbsp;&nbsp;apiKey: "YOUR_API_KEY",</span>
                  <span style="display: block">&nbsp;&nbsp;deck: "NAME_OF_TEMPLATE",</span>
                  <span style="display: block">&nbsp;&nbsp;mogrt: "NAME_OF_MOGRT"</span>
                  <span style="display: block">}</span> </code
                ><i
                  class="fas fa-clone copy"
                  text='{
  apiKey: "YOUR_API_KEY",
  deck: "NAME_OF_TEMPLATE",
  mogrt: "NAME_OF_MOGRT"
}'
                  @click="copyText($event)"
                ></i>
                <p>
                  <strong>This route accepts POST and GET requests.</strong>
                  This will fetch a single mogrt, this mogrt object will contain all its available options. If the mogrt is in a sub folder of the
                  template then you can add the path of the mogrt within the template folder, for example <code>folder/MogrtName</code>.
                </p>
                <br />

                <h4 style="border-top: 1px solid #e7e9ed; padding-top: 30px">
                  <code>https://api.modeck.io/preview</code
                  ><i class="fas fa-clone copy" text="https://api.modeck.io/preview" @click="copyText($event)"></i>
                </h4>
                <code style="display: inline-block; margin-bottom: 30px; margin-top: 20px"
                  ><span style="display: block">{</span>
                  <span style="display: block">&nbsp;&nbsp;apiKey: "YOUR_API_KEY",</span>
                  <span style="display: block">&nbsp;&nbsp;deck: "NAME_OF_TEMPLATE",</span>
                  <span style="display: block">&nbsp;&nbsp;size: "OPTIONAL_NAME_OF_SIZE",</span>
                  <span style="display: block">&nbsp;&nbsp;frame: "OPTIONAL_NAME_OF_PREVIEW_FRAME",</span>
                  <span style="display: block">&nbsp;&nbsp;mogrt: {</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;name: "MOGRT_NAME",</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;options: [{</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;name: "OPTION_NAME",</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;value: "OPTION_VALUE"</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;}]</span>
                  <span style="display: block">&nbsp;&nbsp;}</span>
                  <span style="display: block">}</span> </code
                ><i
                  class="fas fa-clone copy"
                  text='{
  apiKey: "YOUR_API_KEY",
  deck: "NAME_OF_TEMPLATE",
  size: "OPTIONAL_NAME_OF_SIZE",
  frame: "OPTIONAL_NAME_OF_PREVIEW_FRAME",
  mogrt: {
    name: "MOGRT_NAME",
    options: [{
      name: "OPTION_NAME",
      value: "OPTION_VALUE"
    }]
  }
}'
                  @click="copyText($event)"
                ></i>
                <p>
                  <strong>This route accepts POST requests.</strong> This fetches a still preview of the requested mogrt with the requested options
                  applied, the response will contain a single preview frame in base64 format in the given size at the time of the given preview frame
                  name, this is the name of the preview frame as set up in the mogrt file using either
                  <a href="#modk-commands">modk-preview or a preview frame marker</a>.
                </p>
                <br />

                <h4 style="border-top: 1px solid #e7e9ed; padding-top: 30px">
                  <code>https://api.modeck.io/listmedia</code
                  ><i class="fas fa-clone copy" text="https://api.modeck.io/listmedia" @click="copyText($event)"></i>
                </h4>
                <code style="display: inline-block; margin-bottom: 30px; margin-top: 20px"
                  ><span style="display: block">{</span>
                  <span style="display: block">&nbsp;&nbsp;apiKey: "YOUR_API_KEY",</span>
                  <span style="display: block">&nbsp;&nbsp;userEmail: "OPTIONAL_EMAIL"</span>
                  <span style="display: block">}</span> </code
                ><i
                  class="fas fa-clone copy"
                  text='{
  apiKey: "YOUR_API_KEY",
  userEmail: "OPTIONAL_EMAIL"
}'
                  @click="copyText($event)"
                ></i>
                <p>
                  <strong>This route accepts POST and GET requests.</strong>
                  This returns an array of all the files available in the user media folder of the machine running the desktop app. If userEmail is
                  provided then this will only be the media found in that users media folder, otherwise it will be all media.
                </p>
                <br />

                <h4 style="border-top: 1px solid #e7e9ed; padding-top: 30px">
                  <code>https://api.modeck.io/uploadmedia</code
                  ><i class="fas fa-clone copy" text="https://api.modeck.io/uploadmedia" @click="copyText($event)"></i>
                </h4>
                <code style="display: inline-block; margin-bottom: 30px; margin-top: 20px"
                  ><span style="display: block">{</span>
                  <span style="display: block">&nbsp;&nbsp;apiKey: "YOUR_API_KEY",</span>
                  <span style="display: block">&nbsp;&nbsp;deck: "NAME_OF_TEMPLATE",</span>
                  <span style="display: block">&nbsp;&nbsp;filename: "NAME_OF_FILE",</span>
                  <span style="display: block">&nbsp;&nbsp;filetype: "MIME_TYPE_OF_FILE",</span>
                  <span style="display: block">&nbsp;&nbsp;userEmail: "OPTIONAL_EMAIL"</span>
                  <span style="display: block">}</span> </code
                ><i
                  class="fas fa-clone copy"
                  text='{
  apiKey: "YOUR_API_KEY",
  deck: "NAME_OF_TEMPLATE",
  filename: "NAME_OF_FILE",
  filetype: "MIME_TYPE_OF_FILE",
  userEmail: "OPTIONAL_EMAIL"
}'
                  @click="copyText($event)"
                ></i>
                <p>
                  <strong>This route accepts POST requests.</strong>
                  Ensure that the filename is the full filename of the file you will be uploading (eg. "Picture.jpg") and the filetype is set to the
                  correct mime type of the file you will be uploading (eg. "image/jpeg"). If userEmail is provided then this media will be uploaded to
                  that users media library, otherwise it will be associated with the template owner.
                </p>
                <p>
                  This request returns a URL, you can then use a PUT request to upload your file to this URL, the URL will expire in 30 minutes. This
                  PUT request must contain the header "Content-Type" set to the mime type of the file being uploaded (eg. "image/jpeg"). Once the file
                  has been uploaded, the media file will only be downloaded by the app the next time it refreshes, this can be done by selecting
                  "Refresh templates" on the app, or you can make the app refresh using the API, through the /refreshapps route.
                </p>
                <br />

                <h4 style="border-top: 1px solid #e7e9ed; padding-top: 30px">
                  <code>https://api.modeck.io/deletemedia</code
                  ><i class="fas fa-clone copy" text="https://api.modeck.io/deletemedia" @click="copyText($event)"></i>
                </h4>
                <code style="display: inline-block; margin-bottom: 30px; margin-top: 20px"
                  ><span style="display: block">{</span>
                  <span style="display: block">&nbsp;&nbsp;apiKey: "YOUR_API_KEY",</span>
                  <span style="display: block">&nbsp;&nbsp;deck: "NAME_OF_TEMPLATE",</span>
                  <span style="display: block">&nbsp;&nbsp;filename: "NAME_OF_FILE",</span>
                  <span style="display: block">&nbsp;&nbsp;userEmail: "OPTIONAL_EMAIL"</span>
                  <span style="display: block">}</span> </code
                ><i
                  class="fas fa-clone copy"
                  text='{
  apiKey: "YOUR_API_KEY",
  deck: "NAME_OF_TEMPLATE",
  filename: "NAME_OF_FILE",
  userEmail: "OPTIONAL_EMAIL"
}'
                  @click="copyText($event)"
                ></i>
                <p>
                  <strong>This route accepts POST requests.</strong>
                  Delete the file with given filename, if userEmail is supplied then only the media with filename associated with that user will be
                  deleted, otherwise it will delete the file with filename associated with the template owner. Note that this will not remove the file
                  from the rendering machines User media folder if it has already been downloaded by the app, it will only no longer be present in the
                  media library on the online editor.
                </p>
                <br />

                <h4 style="border-top: 1px solid #e7e9ed; padding-top: 30px">
                  <code>https://api.modeck.io/refreshapps</code
                  ><i class="fas fa-clone copy" text="https://api.modeck.io/refreshapps" @click="copyText($event)"></i>
                </h4>
                <code style="display: inline-block; margin-bottom: 30px; margin-top: 20px"
                  ><span style="display: block">{</span>
                  <span style="display: block">&nbsp;&nbsp;apiKey: "YOUR_API_KEY"</span>
                  <span style="display: block">}</span> </code
                ><i
                  class="fas fa-clone copy"
                  text='{
  apiKey: "YOUR_API_KEY"
}'
                  @click="copyText($event)"
                ></i>
                <p>
                  <strong>This route accepts POST and GET requests.</strong>
                  This makes all apps that are currently online refresh their data, this should be called after a media file has completely uploaded
                  using the /uploadmedia route, this will update all the data on the app and download any new media files.
                </p>
                <br />

                <h4 style="border-top: 1px solid #e7e9ed; padding-top: 30px">
                  <code>https://api.modeck.io/getshowcaseclips</code
                  ><i class="fas fa-clone copy" text="https://api.modeck.io/getshowcaseclips" @click="copyText($event)"></i>
                </h4>
                <code style="display: inline-block; margin-bottom: 30px; margin-top: 20px"
                  ><span style="display: block">{</span>
                  <span style="display: block">&nbsp;&nbsp;apiKey: "YOUR_API_KEY",</span>
                  <span style="display: block">&nbsp;&nbsp;deck: "NAME_OF_TEMPLATE",</span>
                  <span style="display: block">&nbsp;&nbsp;mogrt: "OPTIONAL_NAME_OF_MOGRT"</span>
                  <span style="display: block">}</span> </code
                ><i
                  class="fas fa-clone copy"
                  text='{
  apiKey: "YOUR_API_KEY",
  deck: "NAME_OF_TEMPLATE",
  mogrt: "OPTIONAL_NAME_OF_MOGRT"
}'
                  @click="copyText($event)"
                ></i>
                <p>
                  <strong>This route accepts POST and GET requests.</strong>
                  Fetch urls to all the showcase/preview clips that have been added for a template and its mogrts, these are the preview clips as
                  described
                  <a href="#previews">here.</a>
                </p>
                <p>
                  You can optionally add the name of a mogrt, if added then it will only return the showcase for the template and that single mogrt.
                </p>
                <br />

                <h4 style="border-top: 1px solid #e7e9ed; padding-top: 30px">
                  <code>https://api.modeck.io/getusers</code
                  ><i class="fas fa-clone copy" text="https://api.modeck.io/getusers" @click="copyText($event)"></i>
                </h4>
                <code style="display: inline-block; margin-bottom: 30px; margin-top: 20px"
                  ><span style="display: block">{</span>
                  <span style="display: block">&nbsp;&nbsp;apiKey: "YOUR_API_KEY",</span>
                  <span style="display: block">&nbsp;&nbsp;deck: "NAME_OF_TEMPLATE"</span>
                  <span style="display: block">}</span> </code
                ><i
                  class="fas fa-clone copy"
                  text='{
  apiKey: "YOUR_API_KEY",
  deck: "NAME_OF_TEMPLATE"
}'
                  @click="copyText($event)"
                ></i>
                <p>
                  <strong>This route accepts POST and GET requests.</strong>
                  Fetch a list of all the emails of users invited to this template.
                </p>
                <br />

                <h4 style="border-top: 1px solid #e7e9ed; padding-top: 30px">
                  <code>https://api.modeck.io/inviteusers</code
                  ><i class="fas fa-clone copy" text="https://api.modeck.io/inviteusers" @click="copyText($event)"></i>
                </h4>
                <code style="display: inline-block; margin-bottom: 30px; margin-top: 20px"
                  ><span style="display: block">{</span>
                  <span style="display: block">&nbsp;&nbsp;apiKey: "YOUR_API_KEY",</span>
                  <span style="display: block">&nbsp;&nbsp;deck: "NAME_OF_TEMPLATE"</span>
                  <span style="display: block">&nbsp;&nbsp;emails: ["EMAIL_ADDRESS"]</span>
                  <span style="display: block">&nbsp;&nbsp;notify: true</span>
                  <span style="display: block">}</span> </code
                ><i
                  class="fas fa-clone copy"
                  text='{
  apiKey: "YOUR_API_KEY",
  deck: "NAME_OF_TEMPLATE"
}'
                  @click="copyText($event)"
                ></i>
                <p>
                  <strong>This route accepts POST requests.</strong>
                  Invite users to a template, the emails field must be an array containing 1 or more email addresses of users you would like to
                  invite, the notify field is a boolean determining whether or not to send a notification email to each newly invited user.
                </p>
                <br />

                <h4 style="border-top: 1px solid #e7e9ed; padding-top: 30px">
                  <code>https://api.modeck.io/updatelogic</code
                  ><i class="fas fa-clone copy" text="https://api.modeck.io/updatelogic" @click="copyText($event)"></i>
                </h4>
                <code style="display: inline-block; margin-bottom: 30px; margin-top: 20px"
                  ><span style="display: block">{</span>
                  <span style="display: block">&nbsp;&nbsp;apiKey: "YOUR_API_KEY",</span>
                  <span style="display: block">&nbsp;&nbsp;deck: "NAME_OF_TEMPLATE",</span>
                  <span style="display: block">&nbsp;&nbsp;globalOptions: [{</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;name: "OPTION_NAME",</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;type: 13,</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;value: "OPTION_DEFAULT_VALUE",</span>
                  <span style="display: block">&nbsp;&nbsp;&nbsp;&nbsp;menucontent: ["OPTION_MENUCONTENT"]</span>
                  <span style="display: block">&nbsp;&nbsp;}]</span>
                  <span style="display: block">}</span> </code
                ><i
                  class="fas fa-clone copy"
                  text='{
  apiKey: "YOUR_API_KEY",
  deck: "NAME_OF_TEMPLATE",
  globalOptions: [{
    name: "OPTION_NAME",
    type: 13,
    value: "OPTION_DEFAULT_VALUE",
    menucontent: ["OPTION_MENUCONTENT"]
  }]
}'
                  @click="copyText($event)"
                ></i>
                <p>
                  <strong>This route accepts POST requests.</strong>
                  Update the global options within the template logic of a template, if an option in the supplied globalOptions array is named the
                  same as an existing option in the template, then this will update that existing option, if the name does not exist in the templates
                  global options, then it will be added as a new global option.
                </p>
                <p>You can retrieve the existing template logic data using the <code>/getdeck</code> route</p>
                <p>
                  The <code>menucontent</code> array is only relevant for dropdown menu options (type 13) this is an array of the dropdown menu
                  values.
                </p>
                <p>The <code>type</code> value is a number representing the option type, below are the accepted types for global options:</p>
                <ul>
                  <li style="padding: 0">6 = Text</li>
                  <li style="padding: 0">7 = Multiline Text</li>
                  <li style="padding: 0">8 = Comment</li>
                  <li style="padding: 0">13 = Dropdown menu</li>
                  <li style="padding: 0">14 = Media replacement</li>
                </ul>
              </section>
              <!--//docs-intro-->
            </header>
          </article>

          <article class="docs-article">
            <header class="docs-header">
              <h1 class="docs-heading" id="base-mogrts">Base mogrts</h1>
              <section class="docs-intro docs-section">
                <p>
                  A base mogrt is a mogrt that can appear behind all other mogrts in a sequence, allowing the videos to have one consistent element
                  behind all of the mogrts arranged in the online editor, this can be used to have a background that plays throughout an edit, while
                  the mogrt sequence appears in front, could be used to play a footage item under everything, play music throughout etc. make a mogrt
                  a base mogrt by adding the <a href="#modk-commands">modk-base command.</a>
                </p>
                <p>
                  When one or more of the mogrts in a template is set to be a base mogrt, a drop down option appears in the settings of the online
                  editor, allowing an editor to select which mogrt to use as a base mogrt, if you would prefer to make a base mogrt that will always
                  be used for all edits for this template without the editor being able to change the base mogrt, then add the word "always" to the
                  comment value when adding this command to the mogrt.
                </p>
                <p>
                  A common use for this is to make a base mogrt that only contains the music, thus allowing you to make a template that can have that
                  music playing throughout any edits, when an edit with a base mogrt is assembled to render, the base mogrts comp will always have its
                  duration set to the total duration of the edit, meaning you can use
                  <a href="#keyframe-expressions">an expression to connect properties to the comps duration to affect them based on the duration</a>,
                  eg. have music fade out at the end, no matter what the duration
                </p>
              </section>
              <!--//docs-intro-->
            </header>
          </article>

          <article class="docs-article">
            <header class="docs-header">
              <h1 class="docs-heading" id="paid-features">Activating Pro and Enterprise features</h1>
              <section class="docs-intro docs-section">
                <p>
                  To activate the full features of MoDeck,
                  <a href="https://aescripts.com/modeck" target="_blank">head over to aescripts.com and purchase a subscription,</a>
                  you will then be able to find your license key on your aescripts profile
                  <a href="https://aescripts.com/sales/recurring_profile/" target="_blank">here</a>, which can be entered into your
                  <a href="https://app.modeck.io/settings?s=1" target="_blank">MoDeck account settings,</a>
                  this will activate your chosen subscription.
                </p>
                <p>
                  Once a license key has been entered, this key will now remain linked to your account, any changes to your subscription can be made
                  through your aescripts account settings, if you ever top up an existing pay-up-front subscription, or reactive a previously
                  cancelled recurring subscription, be sure to click "Refresh from aescripts" in your account settings to fetch your updated
                  subscription information for your license key from aescripts. If you ever need to change the license key that is linked to your
                  account, please contact support.
                </p>
              </section>
              <!--//docs-intro-->
            </header>
          </article>
        </div>
      </div>
    </div>
    <!--//docs-wrapper-->
  </div>
</template>

<script>
export default {
  name: "docs",
  data() {
    return {
      showNav: true,
    };
  },
  metaInfo() {
    return {
      title: "MoDeck - How to use MoDeck",
    };
  },
  methods: {
    opensilentbox(src) {
      this.$silentbox.open({ src });
    },
    windowResize() {
      if (window.innerWidth > 1199.98) {
        this.showNav = true;
      } else {
        this.showNav = false;
      }
    },
    hideNav() {
      if (window.innerWidth <= 1199.98) {
        this.showNav = false;
      }
    },
    copyText(evt) {
      let text = evt.target.attributes.getNamedItem("text").value;
      // eslint-disable-next-line
      var data = [
        new ClipboardItem({
          "text/plain": new Blob([text], { type: "text/plain" }),
        }),
      ];
      navigator.clipboard.write(data).then(() => {
        evt.target.classList.add("notify");
        setTimeout(() => {
          evt.target.classList.remove("notify");
        }, 2000);
      });
    },
  },
  created() {
    this.windowResize();
    window.addEventListener("resize", this.windowResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.windowResize);
  },
};
</script>

<style src="../assets/css/theme.css" scoped></style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: left 0.3s cubic-bezier(0.87, 0, 0.13, 1);
}
.fade-enter,
.fade-leave-to {
  left: -300px;
}
.example:hover {
  color: #88dce5;
}
.copy {
  margin-left: 5px;
  font-size: 15px;
  cursor: pointer;
}
.copy.notify::after {
  content: "Copied to clipboard";
  pointer-events: none;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-weight: 300;
  background: #6cc2cc;
  padding: 10px;
  border-radius: 8px;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-name: shake-anim;
  position: absolute;
  margin-left: 10px;
  color: white;
}
@keyframes shake-anim {
  0% {
    opacity: 0;
    margin-top: 0;
  }
  15% {
    opacity: 1;
    margin-top: -10px;
  }
  85% {
    opacity: 1;
    margin-top: -10px;
  }
  100% {
    opacity: 0;
    margin-top: 0;
  }
}
</style>
